import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Axios from "../../axios/Axios";
import {Button, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import MainCard from "../../components/MainCard";
import {useDispatch} from "react-redux";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import dayjs from "dayjs";

const PaymentDetail = () => {
    const location = useLocation();
    const idx = location.state.idx;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const initDataForm = {
        email: "",
        name: "",
        curriculum_day: "",
        curriculum_time: "",
        paymentKind: "",
        price: 0,
        status: "N",
        payment_comple_date: "",
        start_date: "",
        end_date: "",
        notes_payment: ""
    }
    const param = {
        idx: idx
    }

    const [orderDetail , setOrderDetail] = useState(initDataForm);

    const handleNotesPaymentDelete = () => {
        dispatch(
            showAlert({
                items: 2,
                title: "삭제하시겠습니까? ",
                message: '수기 결제를 취소하시겠습니까?',
                text1: '확인',
                sucCallback: () => {
                    paymentDelete();
                },
                text2: '취소'
            }),
        );
    }

    const paymentDelete = () => {
        Axios.delete("/cms/payment/paymentDetailDelete" , param , false).then(result => {
            dispatch(
                showAlert({
                    items: 1,
                    title: "삭제되었습니다.",
                    message: '확인을 누르면 목록으로 돌아갑니다.',
                    text1: '확인',
                    sucCallback: () => {
                        navigate("/cms/paymentManagement/paymentList/admin")
                    }
                }),
            );
        })
    }

    const paymentModify = (data) => {
        Axios.patch("/cms/payment/paymentDetailModify" , data , true).then(result => {
            dispatch(
                showAlert({
                    items: 1,
                    title: "수정되었습니다.",
                    message: '확인을 누르면 목록으로 돌아갑니다.',
                    text1: '확인',
                    sucCallback: () => {
                        navigate("/cms/paymentManagement/paymentList/admin")
                    }
                }),
            );
        })
    }

    useEffect(()=> {
        Axios.get("/cms/payment/paymentDetail" , param , false).then(result => {
            setOrderDetail(result.order);
            setPaymentComple(result.order.status === 'Y' ? paymentCompleList[1] : paymentCompleList[0])
        })
    }, [])

    const [paymentCompleList ,setPaymentCompleList] = useState(['미결제' , '결제완료'])
    const [paymentComple ,setPaymentComple] = useState(paymentCompleList[0])
    return (
        <Grid container spacing={2} >
          <MainCard title="결제 상세 정보">
            <Formik
                initialValues={
                    {
                        email: orderDetail.email,
                        name: orderDetail.name,
                        curriculum_day: orderDetail.curriculum_day,
                        curriculum_time: orderDetail.curriculum_time,
                        paymentKind: ( orderDetail.paymentKind === 'noBankBook' ? '무통장입금 ' :
                                            orderDetail.paymentKind === 'virtualAccount' ? '가상계좌' :
                                                orderDetail.paymentKind === 'bankTransfer' ? '계좌이체' :
                                                    orderDetail.paymentKind === 'cardPay' ? '카드결제' : "수기 결제" ),

                        price: orderDetail.price,
                        status: paymentComple,
                        payment_comple_date: orderDetail.payment_comple_date != null ?  dayjs(orderDetail.payment_comple_date).format('YYYY-MM-DD') : "미 결제 상태입니다.",
                        tranning: (orderDetail.start_date != null ? orderDetail.start_date + " ~ " : "미 결제 상태입니다.")
                            + (orderDetail.end_date != null ? orderDetail.end_date : "")
                    }
                }
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={Yup.object().shape(
                )}
                onSubmit={(values, actions) => {
                    let status = 'N';
                    if(paymentComple === '결제완료')
                        status = 'Y';
                    const data = new FormData();
                    data.append("status" , status);
                    data.append("idx" , idx);
                    data.append("paymentKind" , orderDetail.paymentKind);
                    dispatch(
                        showAlert({
                            items: 2,
                            title: "수정하시겠습니까? ",
                            message: '결제 정보를 수정하시겠습니까?',
                            text1: '확인',
                            sucCallback: () => {
                                paymentModify(data);
                            },
                            text2: '취소'
                        }),
                    );

                }}
                enableReinitialize
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="email_id">이메일</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="email_id"
                                                name="email"
                                                value={values.email}
                                                style={{fontWeight:"bold"}}
                                                readOnly={true}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="name_id">이름</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="name_id"
                                                name="name"
                                                value={values.name}
                                                style={{fontWeight:"bold"}}
                                                readOnly={true}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="curriculum_day_id">수강일</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="curriculum_day_id"
                                                name="curriculum_day"
                                                value={values.curriculum_day}
                                                style={{fontWeight:"bold"}}
                                                readOnly={true}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="curriculum_time_id">수업시간</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="curriculum_time_id"
                                                name="curriculum_time"
                                                value={values.curriculum_time}
                                                style={{fontWeight:"bold"}}
                                                readOnly={true}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="paymentKind_id">결제방법</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="paymentKind_id"
                                                name="paymentKind"
                                                value={values.paymentKind}
                                                style={{fontWeight:"bold"}}
                                                readOnly={true}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="price_id">결제금액</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="price_id"
                                                name="price"
                                                value={values.price}
                                                style={{fontWeight:"bold"}}
                                                readOnly={true}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel id="status_id">결제상태</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="status_id"
                                                name="status"
                                                value={paymentComple}
                                                label="결제상태"
                                                onChange={(e)=> {
                                                    setPaymentComple(e.target.value);
                                                }}
                                            >
                                                {
                                                    paymentCompleList.map( (result , i) => {
                                                        return <MenuItem key={i} value={result}>{result}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="payment_comple_date_id">결제일시</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="payment_comple_date_id"
                                                name="payment_comple_date"
                                                value={values.payment_comple_date}
                                                style={{fontWeight:"bold"}}
                                                readOnly={true}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="tranning_id">수강기간</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="tranning_id"
                                                name="tranning"
                                                value={values.tranning}
                                                style={{fontWeight:"bold"}}
                                                readOnly={true}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        { orderDetail.notes_payment === 'Y' &&
                                            <Button type="button" style={{float: 'left', marginTop: 10}} width={40}
                                                    variant="contained" onClick={() => {
                                                        try {
                                                            handleNotesPaymentDelete();
                                                        } catch (e){

                                                        }
                                            }}>삭제</Button>
                                        }
                                        <Button variant="contained"
                                                style={{float: 'right' , marginTop: 10 , marginLeft: 10}} width={40} type="button"
                                                onClick={()=>   navigate("/cms/paymentManagement/paymentList/admin")}>
                                            취소
                                        </Button>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10}} width={40} type="submit">
                                            수정
                                        </Button>
                                    </Grid>
                                </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
          </MainCard>
        </Grid>
    );
}

export default PaymentDetail;