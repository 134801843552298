import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {
    Button,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    OutlinedInput, Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {regNumber} from "../../../utils/reg";
import Axios from "../../../axios/Axios";
import {showAlert} from "../../../store/reducers/alertDialogSlideRedux";
import MainCard from "../../../components/MainCard";


const CurriculumTimeRegistry = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [curriculumUsed , setCurriculumUsed] = useState("Y");

    const handleRadio = (e) => {
        setCurriculumUsed(e.target.value);
    }

    return (
        <Grid container spacing={2} >
            <Formik
                initialValues={{
                    curriculum_time: "",
                    submit: null
                }}
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={Yup.object().shape({
                    curriculum_time: Yup.string().max(255).required('수강 시간을 입력해주세요.')
                })}
                onSubmit={(values, actions) => {
                    const data = new FormData();
                    data.append("curriculum_time" , values.curriculum_time);
                    data.append("used" , curriculumUsed);

                    Axios.post("/cms/curriculum_time/registry" , data , true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "성공",
                                message: '수업시간 등록에 성공하였습니다.',
                                text1: '확인',
                                sucCallback: ()=> {
                                    navigate('/cms/setting/curriculumTime/admin');
                                }
                            }),
                        );
                    });
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="수업시간 등록">
                                <Grid container spacing={4}>
                                    <Grid item sx={{ width: '100%' }} >
                                        <InputLabel htmlFor="curriculum_time">수업시간</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="curriculum_time"
                                            name="curriculum_time"
                                            value={values.curriculum_time}
                                            onBlur={handleBlur}
                                            onChange={ (e) => {handleChange(e)}}
                                            placeholder="수업 시간을 입력해주세요."
                                            style={{fontWeight:"bold"}}
                                            error={Boolean((touched.curriculum_time && errors.curriculum_time))}
                                        />
                                        { (touched.curriculum_time && errors.curriculum_time) && (
                                            <FormHelperText error id="standard-weight-helper-text-curriculum-time">
                                                {errors.curriculum_time}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel id="demo-radio-buttons-group-label">사용 여부</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Y"
                                            name="used-radio-group"
                                            onChange={ (e) => {
                                                handleRadio(e)
                                            }}
                                        >
                                            <FormControlLabel value="Y" control={<Radio size="medium"/>} label="사용" />
                                            <FormControlLabel value="N" control={<Radio size="medium"/>} label="사용 안함" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} width={40} type="button" onClick={()=>   navigate("/cms/setting/curriculumTime/admin")}>
                                            취소
                                        </Button>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} type="submit">
                                            등록
                                        </Button>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>

    );

}

export default CurriculumTimeRegistry;