import {useState} from "react";
import {
    Button,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import MainCard from "../../components/MainCard";
import useAuth from "../../hooks/useAuth";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import Axios from "../../axios/Axios";
import {duplicateEmailCheck} from "./teacherAxiosRequest";
import {regEmail, regId, regPassword, regPhoneNumber} from "../../utils/reg";
import {useNavigate} from "react-router-dom";

const TeacherRegister = () => {
    const {user} = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const [duplicateId , setDuplicateId] = useState(false);
    const [duplicateEmail , setDuplicateEmail] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleEmailOnChange= (e) => {
        setDuplicateEmail(true);
    }

    const handleIdOnChange= (e) => {
        setDuplicateId(true);
    }

    const duplicateIdCheck = (value , dispatch) => {
        if(!regId.test(value)){
            setDuplicateId(true);
            throw new Error("올바르지 않은 형식의 아이디입니다.")
        }

        const data = {
            id: value
        }

        Axios.get("/cms/teacher/duplicateIdCheck" , data , false).then(result => {
            const count = result.data;
            if(count === 0 ){
                setDuplicateId(false);
                dispatch(
                    showAlert({
                        items: 1,
                        title: "정상",
                        message: '사용할 수 있는 아이디 입니다.',
                        text1: '확인',
                    }),
                );
            } else {
                setDuplicateId(true);
                dispatch(
                    showAlert({
                        items: 1,
                        title: "실패",
                        message: "이미 사용중인 아이디입니다.",
                        text1: '확인',
                    }),
                );
            }
        })
    }

    return (
        <Grid container spacing={2} >
            <Formik
                initialValues={
                    user.role === 1 && {
                        id: "",
                        name: "",
                        email: "",
                        phoneNumber: "",
                        password: "",
                        submit: null
                    }
                }
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={
                    Yup.object().shape(
                        user.role === 1 && {
                            id: Yup.string().matches( regId , '영어 , 숫자만 입력가능합니다.').max(255).required('아이디를 입력해주세요.'),
                            name: Yup.string().max(255).required('이름을 입력해주세요.'),
                            email: Yup.string().max(255).matches(regEmail , "이메일 형식을 입력해 주세요.").required('이메일을 입력해주세요.'),
                            password: Yup.string().matches(regPassword , "9자리 이상 20자리 이하 영어,숫자,특수문자 를 포함하여야합니다.").max(255).required('비밀번호를 입력해주세요.'),
                            phoneNumber:  Yup.string().max(255).matches(regPhoneNumber, "숫자만 입력 가능합니다.").required('휴대폰번호를 입력해주세요.')
                        }
                   )
                }

                onSubmit={(values, actions) => {

                    if(duplicateId || duplicateEmail) {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "실패",
                                message: "중복검사를 진행해 주세요.",
                                text1: '확인',
                            }),
                        );
                        throw new Error("중복검사를 진행해 주세요.");
                    }
                    const data = new FormData();
                    data.append("id" , values.id);
                    data.append("name" , values.name);
                    data.append("email" , values.email);
                    data.append("password" , values.password);
                    data.append("phone_number" , values.phoneNumber);
                    Axios.post("/cms/teacher/join" , data , true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "성공",
                                message: "정상적으로 등록되었습니다.",
                                text1: '확인',
                                sucCallback: () => {
                                    navigate('/cms/teacherManage/teacherList/admin')
                                }
                            }),
                        );
                    })
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="강사 등록">
                                <Grid container spacing={4} rowSpacing={1}>
                                    <Grid item sx={{ width: '80%' }} >
                                        <InputLabel htmlFor="id">아이디</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="id"
                                            name="id"
                                            value={values.id}
                                            onBlur={handleBlur}
                                            onChange={ (e) => {
                                                handleChange(e)
                                                handleIdOnChange(e)
                                            }}
                                            placeholder="아이디를 입력해주세요."
                                            style={{fontWeight:"bold"}}
                                            error={Boolean( (touched.id && errors.id ) || duplicateId)}
                                        />
                                        { (touched.id && errors.id ) && (
                                            <FormHelperText error id="standard-weight-helper-text-id">
                                                {errors.id}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '20%'  , marginTop: '24px' }} >
                                        <Button fullWidth type="button" onClick={() => {
                                            try {
                                                duplicateIdCheck(values.id , dispatch)
                                            } catch (e) {
                                                dispatch(
                                                    showAlert({
                                                        items: 1,
                                                        title: "실패",
                                                        message: '올바르지 않은 아이디 입니다.',
                                                        text1: '확인',
                                                    }),
                                                );
                                            }

                                        }}>
                                            아이디 중복 검사
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="name">이름</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="name"
                                                name="name"
                                                value={values.name}
                                                type="text"
                                                placeholder="이름을 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean( touched.name && errors.name)}
                                            />
                                        </Stack>
                                        { touched.name && errors.name && (
                                            <FormHelperText error id="standard-weight-helper-text-name">
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*구분 div 역할 세로 일경우에  direction="row"  divider={<Divider orientation="vertical" flexItem />} 다이버 설정가능 spacing = 간격 */}
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="password">비밀번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={values.password}
                                                onBlur={handleBlur} // 값 검증시 아무것도 없으면 이벤트
                                                onChange={handleChange}
                                                placeholder="비밀번호를 입력해주세요."
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            color="secondary"
                                                        >
                                                            {/* 아이콘 보여줄 ui 가져옴 눈표시 */}
                                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                error={Boolean(touched.password && errors.password)}
                                            />
                                        </Stack>
                                        {touched.password && errors.password && (
                                            <FormHelperText error id="standard-weight-helper-text-password">
                                                {errors.password}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '80%' }} >
                                            <InputLabel htmlFor="email">이메일</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="email"
                                                name="email"
                                                value={values.email}
                                                placeholder="이메일을 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                color={duplicateEmail ? 'warning' : 'primary'}
                                                type="email"
                                                onBlur={handleBlur}
                                                onChange={ (e) => {
                                                    handleChange(e)
                                                    handleEmailOnChange(e)
                                                }}
                                                error={Boolean(( touched.email && errors.email )|| duplicateEmail)} // true 일때 빨간색으로 됨.
                                            />
                                        { ( touched.email && errors.email) && (
                                            <FormHelperText error id="standard-weight-helper-text-email">
                                                {errors.email}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '20%'  , marginTop: '24px' }} >
                                        <Button fullWidth type="button" onClick={ () => {
                                            try {
                                                duplicateEmailCheck(values.email , dispatch , setDuplicateEmail)
                                            } catch (e) {
                                                dispatch(
                                                    showAlert({
                                                        items: 1,
                                                        title: "실패",
                                                        message: e.message,
                                                        text1: '확인',
                                                    }),
                                                );
                                            }
                                        }}>
                                            이메일 중복 검사
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="phone_number">휴대폰 번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="phone_number"
                                                name="phoneNumber"
                                                value={values.phoneNumber}
                                                type="text"
                                                placeholder="휴대폰번호를 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean( touched.phoneNumber && errors.phoneNumber)}
                                            />
                                        </Stack>
                                        { touched.phoneNumber && errors.phoneNumber && (
                                            <FormHelperText error id="standard-weight-helper-text-phoneNumber">
                                                {errors.phoneNumber}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} width={40} type="button" onClick={()=>   navigate("/cms/teacherManage/teacherList/admin")}>
                                            취소
                                        </Button>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} type="submit">
                                            등록
                                        </Button>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    )
}

export default TeacherRegister;