import {createContext, useEffect} from "react";
import {useReducer} from "react";
import  authReducer from "store/reducers/auth"
import PropTypes from "prop-types";
import {store} from "../store";
/**
 * 전역적으로 값을 활용하기 위한 함수 createContext
 * @type {React.Context<null>}
 */
const MemberContext = new createContext(null);

const isToken = store.getState().authToken.token;

const initialState = {
    isLoggedIn: isToken != null, // 로그인 여부
    isInitialized: false, // 초기화 여부
    user: null // 로그인한 유저
};

export const MemberProvider = ({children}) => {


    /**
     * 첫 번째 파라미터에는 리듀서 함수를 넣고, 두 번째 파라미터에는 해당 리듀서의 기본값을 넣어 준다.
     * state는 현재 가리키고 있는 상태고, dispatch는 액션 발생시키는 함수입니다.
     * dispatch(action)과 같은 형태로, 함수 안에 파라미터로 액션 값을 넣어 주면 리듀서 함수가 호출되는 구조입니다.
     */
    const [state, dispatches] = useReducer(authReducer, initialState);

    //
    // const navigate = useNavigate();
    //
    //
    // useEffect( ()=> {
    //     const localUser = localStorage.getItem("user")
    //     console.log("null")
    //     if(state.user == null && localUser != null){
    //         console.log("널이 아닌데 왜타죠")
    //         const decryptUser = CryptoJS.AES.decrypt(localUser, 'testAES');
    //         const loginUser = JSON.parse(decryptUser.toString(CryptoJS.enc.Utf8));
    //         const dispatcheFunction = async () => {
    //            await dispatches({
    //                 type: RELOGIN,
    //                 payload: {
    //                     isLoggedIn: true,
    //                     user: {
    //                         id: loginUser.id,
    //                         name: loginUser.name,
    //                         role: loginUser.role
    //                     }
    //                 }
    //             });
    //         }
    //         dispatcheFunction().then(r => {
    //             console.log("끝 났을 때 유저 = " , r);
    //             console.log("끝 났을 때 유저 = " , state);
    //         });
    //     }
    // }, [dispatches])

    return (
        <MemberContext.Provider
            value={{
                ...state,
                dispatches
            }}
        >
            {children}
        </MemberContext.Provider>
    );
}

MemberProvider.propTypes = {
    children: PropTypes.node
};

export default MemberContext;
