import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isToken: false,
    token: ""
};

const authToken = createSlice({
    name: 'authToken',  // state 이름
    initialState,// state 값
    reducers: { // 수정하는 함수 // state -> 기존 스테이트 ,
        activeAuthToken(state, action) {
            state.isToken = action.payload.isToken;
            state.token = action.payload.token;
        }
    }
});


export default authToken.reducer;

export const { activeAuthToken } = authToken.actions;