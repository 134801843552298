// action - state management
import { REGISTER, LOGIN, LOGOUT , RELOGIN } from './actions';
import {store} from "../index"

const isToken  = store.getState().authToken.isToken;

// initial state
export const initialState = {
  isLoggedIn: isToken,
  isInitialized: false,
  user: null
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER: { // 회원 가입
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case RELOGIN: {
      const { user } = action.payload;
      return {
        isInitialized: true,
        isLoggedIn: true,
        ...state,
        user,
      }
    }
    case LOGIN: { // 로그인
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case LOGOUT: { // 로그아웃
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
