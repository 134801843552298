import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import LogoIcon from './LogoIcon';
import LogoMain from './LogoMain';
import config from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, isIcon, sx, to }) => (
    /**
     * disableRipple => 이면 true파급 효과가 비활성화됩니다.
     * ⚠️ 잔물결이 없으면 기본적으로 :focus-visible에 대한 스타일 지정이 없습니다. 클래스 에 별도의 스타일을 적용하여 요소를 강조 표시해야 합니다 .Mui-focusVisible.
     *
     * component =
     * 루트 노드에 사용되는 구성 요소입니다. HTML 요소 또는 구성 요소를 사용할 문자열입니다.
     * ⚠️ 심판을 받을 수 있어야 합니다 .
     *
     * to = 링크 보낼 곳
     *
     * sx = >
     * 시스템 재정의 및 추가 CSS 스타일을 정의할 수 있는 시스템 소품입니다. 자세한 내용은 `sx` 페이지 를 참조하세요.
     *
     */
  <ButtonBase disableRipple to={!to ? config.defaultPath : to} sx={sx}>
    {isIcon ? <LogoIcon /> : <LogoMain reverse={reverse} />}
  </ButtonBase>
);

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
