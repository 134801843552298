import {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {getter} from "@progress/kendo-react-common";
import {getSelectedState, Grid, GridColumn} from "@progress/kendo-react-grid";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import Axios from "../../axios/Axios";
import {Button, Grid as MuiGrid, MenuItem, OutlinedInput, Select, Typography} from "@mui/material";
import moment from "moment";


const PaymentList = () => {
    const [category , setCategory] = useState("email");
    const [keyword , setKeyword] = useState("");
    const [paymentList , setPaymentList ] = useState([]);
    const [total ,setTotal] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /**
     *  REST 요청 카테고리 및 상태 변경
     * @param event
     */
    const handleChange = (event) => {
        const value = event.target.value;
        setCategory(value)
    };

    /**
     * 검색 키워드
     * @param event
     */
    const keywordHandleChange = (event) => {
        setKeyword(event.target.value);
    }

    /**
     * 페이지 처리
     * @type {{take: number, skip: number}}
     */
    let param = {
        skip: 0,
        take: 10
    }

    const [kendoPage, setKendoPage] = useState(param);
    const [page, setPage] = useState(1);
    /**
     * REST 요청 키워드
     * @type {{category: string, keyword: string}}
     */
    let categorySelect = {
        category: category,
        keyword: keyword,
        per_page: 10,
        page: page
    }
    /**
     * 페이지 변경
     * @param event
     */
    const pageChange = (event) => {
        setKendoPage(event.page);
        setPage((event.page.skip/10)+1);
    };



    /**
     * 카테고리 검색히 다시 페이지 1로 초기화
     */
    const categorySelectFunction = () => {
        setPage(page);
        axiosForm();
    }

    const enterFunction = (event) => {
        if(event.keyCode === 13){
            categorySelectFunction()
        }
    }

    /**
     * axios
     */
    let axiosForm = () => {
        Axios.get("/cms/payment/paymentList" ,  categorySelect).then(result => {
                const resultList = result.data.res;
                setPaymentList(resultList);
                setTotal(result.data.total[0].total);
            }
        )
    }

    useEffect( () => {
        axiosForm();
    } , [page])

    return (
        <>
            <MuiGrid container spacing={3} rowSpacing={1} marginBottom={10}>
                <MuiGrid item sx={{ width: '30%'}}>
                    <Select
                        fullWidth
                        labelId="select-label"
                        id="simple-select"
                        value={category}
                        onChange={handleChange}
                    >
                        <MenuItem value={'name'}>이름</MenuItem>
                        <MenuItem value={'email'}>이메일</MenuItem>
                    </Select>

                </MuiGrid>
                <MuiGrid item sx={{ width: '55%'}}>
                    <OutlinedInput
                        fullWidth
                        placeholder="검색어를 입력해주세요."
                        onChange={keywordHandleChange}
                        onKeyDown={enterFunction}
                    />
                </MuiGrid>
                <MuiGrid item sx={{ width: '15%' }}>
                    <Button  type="button" onClick={() => {
                        categorySelectFunction()
                    }} >검색</Button>
                </MuiGrid>
            </MuiGrid>
            <MuiGrid item sx={{ width: '100%'}}>
                <Typography  style={{float:"right" , marginRight:2}} variant="h6">총 {total} 건</Typography>
            </MuiGrid>
            <Grid
                style={{ height: "400px" }}
                data={paymentList}
                pageable={{ buttonCount: 5 }}
                onPageChange={pageChange}
                total={total}
                skip={kendoPage.skip}
            >
                <GridColumn field="row_num" title="번호" width="100" />
                <GridColumn field="email" title="이메일" width="150" cell={paymentDetailHref}/>
                <GridColumn field="name" title="학습자 이름" width="150" style={{width:"50%"}} />
                <GridColumn field="paymentKind" title="결제방법" width="160" cell={paymentKindChange} />
                <GridColumn field="price" title="결제금액" width="150" />
                <GridColumn field="status" title="결제상태" style={{width:"15%"}} cell={payStatus}/>
                <GridColumn field="payment_comple_date" title="결제완료일" style={{width:"15%"}} cell={payment_comple_date_change}/>
                <GridColumn field="request_date" title="결제요청일" style={{width:"15%"}} cell={request_date_change}/>
            </Grid>
            <MuiGrid item xs={6}>
                <Button type="button" style={{float: 'right' , marginTop: 10}} width={40} variant="contained" onClick={()=> navigate("/cms/paymentManagement/handlePaymentRegistry/admin")}>수기 결제 등록</Button>
            </MuiGrid>
        </>
    )
}


const paymentDetailHref = (props) => {
    return (
        <td>
            <Link to={`/cms/paymentManagement/paymentDetail/${props.dataItem.idx}/admin`} state={{ idx: props.dataItem.idx }}>{props.dataItem.email}</Link>
        </td>
    )

}


const paymentKindChange = (props) => {
    return (
        <td>
            <p>{props.dataItem.paymentKind === 'noBankBook' ? '무통장입금 ' :
                props.dataItem.paymentKind === 'virtualAccount' ? '가상계좌' :
                    props.dataItem.paymentKind === 'bankTransfer' ? '계좌이체' :
                        props.dataItem.paymentKind === 'cardPay' ? '카드결제' : "수기 결제"} </p>
        </td>
    )

}

const payStatus = (props) => {
    return (
        <td>
            <p>{props.dataItem.status === 'Y' ? "결제완료" : "미결제"} </p>
        </td>
    )
}

const payment_comple_date_change = (props) => {
    return (
        <td>
            <p>{ props.dataItem.payment_comple_date != null ? moment(props.dataItem.payment_comple_date).format('YYYY-MM-DD') : null  } </p>
        </td>
    )
}

const request_date_change = (props) => {
    return (
        <td>
            <p>{moment(props.dataItem.request_date).format('YYYY-MM-DD')} </p>
        </td>
    )
}
export default PaymentList;