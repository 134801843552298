import MyPageSetting from "../pages/myPage/MyPageSetting";
import MyPagePasswordSetting from "../pages/myPage/MyPagePasswordSetting";
import AuthGuard from "../utils/route-guard/AuthGuard";
import Loadable from "../components/Loadable";
import {lazy} from "react";
import UserRegister from "../pages/user/UserRegister";
import TeacherRegister from "../pages/teacher/TeacherRegister";
import TeacherList from "../pages/teacher/TeacherList";
import TeacherDetail from "../pages/teacher/TeacherDetail";
import UserList from "../pages/user/UserList";
import UserDetail from "../pages/user/UserDetail";
import HandlePaymentRegistry from "../pages/payment/HandlePaymentRegistry";
import AccountSetting from "../pages/payment/AccountSetting";
import CurriculumDayList from "../pages/curriculum/daySetting/CurriculumDayList";
import CurriculumDayRegistry from "../pages/curriculum/daySetting/CurriculumDayRegistry";
import CurriculumDayDetail from "../pages/curriculum/daySetting/CurriculumDayDetail";
import CurriculumTimeRegistry from "../pages/curriculum/timeSetting/CurriculumTimeRegistry";
import CurriculumTimeList from "../pages/curriculum/timeSetting/CurriculumTimeList";
import CurriculumTimeDetail from "../pages/curriculum/timeSetting/CurriculumTimeDetail";
import UserDetailTeacher from "../pages/user/UserDetailTeacher";
import PaymentList from "../pages/payment/PaymentList";
import PaymentDetail from "../pages/payment/PaymentDetail";
// ==============================|| MAIN ROUTING ||============================== //
const CommonLayoutLazy = Loadable(lazy( () => import("layout/CommonLayout")));



const CommonRoutes = {
  path: '/cms',
  element: (
      <AuthGuard>
        <CommonLayoutLazy />
      </AuthGuard>
  ),
  children: [
    {
      path: 'myPage',
      children: [
        {
          path: 'setting',
          children: [
            {
              path: 'teacher',
              element:(
                  <MyPageSetting/>
              )
            },
            {
              path: 'admin',
              element:(
                  <MyPageSetting />
              )
            }
          ]
        },
        {
          path: 'passwordSetting',
          children: [
            {
              path: 'teacher',
              element:(
                  <MyPagePasswordSetting />
              )
            },
            {
              path: 'admin',
              element:(
                  <MyPagePasswordSetting />
              )
            }
          ]
        }
      ]
    },
    {
      path: 'teacherManage',
      children: [
        {
          path: 'teacherRegister/admin' ,
          element: (
              <TeacherRegister />
          )
        },
        {
          path: 'teacherList/admin' ,
          element: (
              <TeacherList />
          )
        },
        {
          path: 'teacherDetail/admin/:id',
          element: (
              <TeacherDetail />
          )
        }
      ]
    },
    {
      path: 'user',
      children: [
        {
          path: 'userRegister/admin',
          element: <UserRegister/>
        },
        {
          path: 'userList/admin',
          element: <UserList/>
        },
        {
          path: 'userList/teacher',
          element: <UserList/>
        },
        {
          path: "userDetail/:email/admin",
          element: <UserDetail />
        },
        {
          path: "userDetail/:email/teacher",
          element: <UserDetailTeacher />
        }
      ]
    },
    {
      path: 'paymentManagement',
      children: [
        {
          path: 'handlePaymentRegistry/admin',
          element: <HandlePaymentRegistry />
        },
        {
          path: 'paymentList/admin',
          element: <PaymentList />
        },
        {
          path: 'paymentDetail/:idx/admin',
          element: <PaymentDetail />
        }
      ]
    },
    {
      path: 'setting',
      children: [
        {
          path: 'accountSetting/admin',
          element: <AccountSetting />
        },
        {
          path: 'curriculumDay/admin',
          element: <CurriculumDayList />
        },
        {
          path: 'curriculumDayRegistry/admin',
          element: <CurriculumDayRegistry />
        },
        {
          path: 'curriculumDayDetail/:idx/admin',
          element: <CurriculumDayDetail />
        },
        {
          path: 'curriculumTimeRegistry/admin',
          element: <CurriculumTimeRegistry />
        },
        {
          path: 'curriculumTime/admin',
          element: <CurriculumTimeList />
        },
        {
          path: 'curriculumTimeDetail/:idx/admin',
          element: <CurriculumTimeDetail />
        },
      ]
    }
  ]
};

export default CommonRoutes;
