import {useState, useRef} from "react";
import {Formik} from "formik";
import * as Yup from 'yup';

// material-ui
import {useTheme} from '@mui/material/styles';
import {
    Box,
    Grid,
    ButtonBase,
    Stack,
    Typography,
    InputLabel,
    OutlinedInput,
    Button,
    FormHelperText,
    Link,
    Container,
    useMediaQuery, InputAdornment, IconButton
} from '@mui/material';

import Logo from "components/logo";
import MainCard from "components/MainCard";
import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {activeAuthName, activeAuthType} from "../../store/reducers/type";
import {LOGIN} from "../../store/reducers/actions";
import useAuth from "../../hooks/useAuth";
import {setLocalStorageExpireTime} from "../../utils/setLocalStorageExpireTime";
import Axios from "../../axios/Axios";
import {activeAuthToken} from "../../store/reducers/authToken";

// ================================|| LOGIN ||================================ //

const AdminLogin = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [showPassword, setShowPassword] = useState(false);
    const inputIdRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {dispatches}  = useAuth();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const loginSuccessRedirectFunction = (type) => {
        (type === 0 || type === 1) ? navigate("/cms/myPage/setting/admin") : navigate("/error")
    }

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                {/*# region 로고영역*/}
                <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
                    <ButtonBase>
                        <Logo />
                    </ButtonBase>
                </Grid>
                {/*# endregion*/}
                {/*# region 로그인폼영역*/}
                <Formik
                    initialValues={{
                        loginId: '',
                        password: '',
                        submit: null
                    }}
                    // Yub -> Validation 라이브러리
                    // html name 을 인식해서 사용함.
                    validationSchema={Yup.object().shape({
                        loginId: Yup.string().max(255).required('아이디를 입력해주세요.'),
                        password: Yup.string().max(255).required('비밀번호를 입력해주세요.')
                    })}
                    onSubmit={(values, actions) => {

                        const data = new FormData();
                        data.append("id" , values.loginId);
                        data.append("password" , values.password);

                        Axios.post("/cms/auth/login" , data , true).then(result => {

                                const token = result.token;
                                const findName = result.name;
                                const type = result.type;
                                dispatch( activeAuthType( {authType: type}));
                                dispatch( activeAuthName( {authName: findName}));
                                dispatch(
                                    activeAuthToken({
                                        isToken: true,
                                        token: token
                                    })
                                );
                                dispatches({
                                    type: LOGIN,
                                    payload: {
                                        isLoggedIn: true,
                                        user: {
                                            id: values.loginId,
                                            name: findName,
                                            role: type
                                        }
                                    }
                                })

                                const member = {
                                    id: values.loginId,
                                    name: findName,
                                    role: type
                                }
                                // 30분

                                setLocalStorageExpireTime('user' , member , 18000000)
                                // const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(member), 'testAES').toString();
                                // //const localUser = JSON.stringify(member);
                                // localStorage.setItem("user" , ciphertext);
                                loginSuccessRedirectFunction(type);
                            })
                        }
                    }
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid item xs={12}>
                                <Grid item xs={12} container justifyContent="center" alignItems="center" sx={{ minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}>
                                    <Grid item>
                                        <MainCard sx={{ maxWidth: { xs: 400, lg: 475 }, margin: { xs: 2.5, md: 3 }, '& > *': { flexGrow: 1, flexBasis: '50%' }}}
                                                  content={false}
                                                  border={false}
                                                  boxShadow
                                                  shadow={theme.customShadows.z1}
                                        >
                                            <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Stack direction="row" justifyContent="center" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                                                            <Typography color="primary" variant="h2" fontWeight="bold">LUCY MAX 관리자 로그인 </Typography>
                                                            {/*<Typography component={Link} to={'/register'} variant="body1" sx={{ textDecoration: 'none' }} color="primary">*/}
                                                            {/*  비밀번호를 잃어버리셨습니까?*/}
                                                            {/*</Typography>*/}
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Stack spacing={1}>
                                                                    <InputLabel htmlFor="login-id">아이디</InputLabel>
                                                                    <OutlinedInput
                                                                        id="login-id"
                                                                        type="text"
                                                                        name="loginId"
                                                                        // html 기본값
                                                                        value={values.loginId}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        placeholder="아이디를 입력해 주세요."
                                                                        fullWidth
                                                                        autoFocus= {true}
                                                                        // FormMick 사용 에러 표시 해주는 기능
                                                                        error={Boolean(touched.loginId && errors.loginId)}
                                                                        ref = {inputIdRef}
                                                                    />
                                                                </Stack>
                                                                {/*에러 터졌을 때 보여주는 필드*/}
                                                                {touched.loginId && errors.loginId && (
                                                                    <FormHelperText error id="standard-weight-helper-text-login-id">{errors.loginId}</FormHelperText>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {/*구분 div 역할 세로 일경우에  direction="row"  divider={<Divider orientation="vertical" flexItem />} 다이버 설정가능 spacing = 간격 */}
                                                                <Stack spacing={1}>
                                                                    <InputLabel htmlFor="password-login">비밀번호</InputLabel>
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        id="password-login"
                                                                        type={showPassword ? 'text' : 'password'}
                                                                        name="password"
                                                                        value={values.password}
                                                                        onBlur={(event) => {
                                                                            handleBlur(event);
                                                                        }} // 값 검증시 아무것도 없으면 이벤트  onBlur 이거는 그거임 뭐냐 포커싱했다가 언포커싱 되면 김
                                                                        onChange={handleChange}
                                                                        placeholder="비밀번호를 입력해주세요."
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={handleClickShowPassword}
                                                                                    onMouseDown={handleMouseDownPassword}
                                                                                    edge="end"
                                                                                    color="secondary"
                                                                                >
                                                                                    {/* 아이콘 보여줄 ui 가져옴 눈표시 */}
                                                                                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        }
                                                                        error={Boolean(touched.password && errors.password)}
                                                                    />
                                                                </Stack>
                                                                {touched.password && errors.password && (
                                                                    <FormHelperText error id="standard-weight-helper-text-password-login">
                                                                        {errors.password}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            {/*<Grid item xs={12} sx={{ mt: -1 }}>*/}
                                                            {/*  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>*/}
                                                            {/*    <FormControlLabel*/}
                                                            {/*      control={*/}
                                                            {/*        <Checkbox*/}
                                                            {/*          checked={checked}*/}
                                                            {/*          onChange={(event) => setChecked(event.target.checked)}*/}
                                                            {/*          name="checked"*/}
                                                            {/*          color="primary"*/}
                                                            {/*          size="small"/>*/}
                                                            {/*      }*/}
                                                            {/*      label={<Typography variant="h6">Keep me sign in</Typography>}*/}
                                                            {/*    />*/}
                                                            {/*    <Link variant="h6" component={RouterLink} to={'/forgot-password'} color="text.primary">*/}
                                                            {/*      Forgot Password?*/}
                                                            {/*    </Link>*/}
                                                            {/*  </Stack>*/}
                                                            {/*</Grid>*/}
                                                            <Grid item xs={12}>
                                                                <Button disableElevation fullWidth size="large" type="submit" variant="contained" color="primary" >
                                                                    로그인
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </MainCard>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
                {/*# endregion*/}
                {/*# region 푸터영역*/}
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <Container maxWidth="xl">
                        <Stack
                            direction={matchDownSM ? 'column' : 'row'}
                            justifyContent={matchDownSM ? 'center' : 'space-between'}
                            spacing={2}
                            textAlign={matchDownSM ? 'center' : 'inherit'}
                        >
                            <Typography variant="subtitle2" color="secondary" component="span">
                                This site is protected by{' '}
                                <Typography component={Link} variant="subtitle2" href="#mantis-privacy" target="_blank" underline="hover">
                                    Privacy Policy
                                </Typography>
                            </Typography>
                            <Stack direction={matchDownSM ? 'column' : 'row'} spacing={matchDownSM ? 1 : 3} textAlign={matchDownSM ? 'center' : 'inherit'}>
                                <Typography
                                    variant="subtitle2"
                                    color="secondary"
                                    component={Link}
                                    href="https://codedthemes.com"
                                    target="_blank"
                                    underline="hover"
                                >
                                    Terms and Conditions
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    color="secondary"
                                    component={Link}
                                    href="https://codedthemes.com"
                                    target="_blank"
                                    underline="hover"
                                >
                                    Privacy Policy
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    color="secondary"
                                    component={Link}
                                    href="https://codedthemes.com"
                                    target="_blank"
                                    underline="hover"
                                >
                                    CA Privacy Notice
                                </Typography>
                            </Stack>
                        </Stack>
                    </Container>
                </Grid>
                {/*# endregion*/}
            </Grid>
        </Box>
    );
};

export default AdminLogin;
