import {
    Button,
    FormHelperText,
    Grid,
    InputLabel, OutlinedInput,
    Stack,
} from "@mui/material";

import MainCard from "../../components/MainCard";
import {useEffect, useState} from "react";
import useAuth from "../../hooks/useAuth";
import * as Yup from "yup";
import {Formik} from "formik";
import {RELOGIN} from "../../store/reducers/actions";
import {setLocalStorageExpireTime} from "../../utils/setLocalStorageExpireTime";
import {useDispatch} from "react-redux";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import Axios from "../../axios/Axios";
import {regEmail, regPhoneNumber} from "../../utils/reg";

const MyPageSetting = () => {

  const {user , dispatches} = useAuth();
  const [id , setId] = useState("");
  const [name , setName] = useState("");
  const [email , setEmail] =  useState("");
  const [phoneNumber ,setPhoneNumber] = useState("");
  const [duplicateEmail , setDuplicateEmail] = useState(false);
  const dispatch = useDispatch();

  const settingMember = (result) => {
    setId(result.id)
    setEmail(result.email)
    setName(result.name)
    result.phone_number !== undefined && setPhoneNumber(result.phone_number)
  }

  const handleEmailOnChange= (e) => {
    setDuplicateEmail(true);
  }

  useEffect(() => {
    if(user != null) {
        user.role === 1 && Axios.get("/cms/admin/my_detail" , null , false).then(result => {
            settingMember(result.data)
        })
        user.role === 0 && Axios.get("/teacher/mypage/my_detail" , null , false).then(result => {
            settingMember(result.data)
        })
    }
  } , [user])

  return (
      <Grid container spacing={2} >
        <Formik
            initialValues={
               user.role === 0 ? {
                  id: id,
                  name: name,
                  email: email,
                  phoneNumber: phoneNumber,
                  submit: null
                } : {
                  id: id,
                  name: name,
                  email: email,
                  submit: null
               }
            }
            // Yub -> Validation 라이브러리
            // html name 을 인식해서 사용함.
            validationSchema={Yup.object().shape(
             user.role === 0 ? {
                          id: Yup.string().max(255).required('아이디 없이 보낼 수 없습니다.'),
                          name: Yup.string().max(255).required('이름을 입력해주세요.'),
                          email: Yup.string().max(255).matches(regEmail , "이메일 형식을 입력해 주세요.").required('이메일을 입력해주세요.'),
                          phoneNumber:  Yup.string().max(255).matches(regPhoneNumber, "숫자만 입력 가능합니다.").required('휴대폰번호를 입력해주세요.')
                        } : {
                          id: Yup.string().max(255).required('아이디 없이 보낼 수 없습니다.'),
                          name: Yup.string().max(255).required('이름을 입력해주세요.'),
                          email: Yup.string().max(255).matches(regEmail, "이메일 형식을 입력해 주세요.").required('이메일을 입력해주세요.')
                       }
            )}
            onSubmit={(values, actions) => {

                const data = new FormData();
                data.append("id" , values.id);
                data.append("name" , values.name);
                data.append("email" , values.email);
                (values.phoneNumber !== "" && values.phoneNumber != null) && data.append("phone_number" , values.phoneNumber);


                user.role === 1 && Axios.patch("/cms/admin/my_modify" , data , true).then(result => {
                        if(user.name !== values.name){
                            dispatches({
                                type: RELOGIN,
                                payload: {
                                    user: {
                                        id: id,
                                        name: values.name,
                                        role: user.role
                                    }
                                }
                            })
                            const member = {
                                id: id,
                                name: values.name,
                                role: user.role
                            }
                            localStorage.removeItem("user")
                            setLocalStorageExpireTime('user' , member , 18000000)
                        }
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "정상",
                                message: "정상적으로 변경되었습니다.",
                                text1: '확인',
                            }),
                        );
                })

                user.role === 0 && Axios.patch("/teacher/mypage/my_modify" , data , true).then(result => {
                    if(user.name !== values.name){
                        dispatches({
                            type: RELOGIN,
                            payload: {
                                user: {
                                    id: id,
                                    name: values.name,
                                    role: user.role
                                }
                            }
                        })
                        const member = {
                            id: id,
                            name: values.name,
                            role: user.role
                        }
                        localStorage.removeItem("user")
                        setLocalStorageExpireTime('user' , member , 18000000)
                    }
                    dispatch(
                        showAlert({
                            items: 1,
                            title: "정상",
                            message: "정상적으로 변경되었습니다.",
                            text1: '확인',
                        }),
                    );
                })
            }}
            enableReinitialize
        >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid item xs={12} md={12}>
              <MainCard title="내 정보 설정">
                <Grid container spacing={user.role === 0 ? 4 : 3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="id">아이디</InputLabel>
                        <OutlinedInput
                          fullWidth
                          id="id"
                          name="id"
                          value={values.id}
                          style={{fontWeight:"bold"}}
                          readOnly={true}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">이름</InputLabel>
                      <OutlinedInput
                          fullWidth
                          id="name"
                          name="name"
                          value={values.name}
                          type="text"
                          style={{fontWeight:"bold"}}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(errors.name)}
                      />
                    </Stack>
                    { errors.name && (
                        <FormHelperText error id="standard-weight-helper-text-name">
                          {errors.name}
                        </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email">이메일</InputLabel>
                      <OutlinedInput
                          fullWidth
                          id="email"
                          name="email"
                          value={values.email}
                          style={{fontWeight:"bold"}}
                          type="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(errors.email)}
                      />
                    </Stack>
                    { errors.email && (
                        <FormHelperText error id="standard-weight-helper-text-email">
                          {errors.email}
                        </FormHelperText>
                    )}
                  </Grid>
                    { user.role === 0 && (
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="phone_number">휴대폰 번호</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    id="phone_number"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    type="text"
                                    style={{fontWeight:"bold"}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={Boolean(errors.phoneNumber)}
                                />
                            </Stack>
                            { errors.phoneNumber && (
                                <FormHelperText error id="standard-weight-helper-text-phone-number">
                                    {errors.phoneNumber}
                                </FormHelperText>
                            )}
                        </Grid>
                        )
                    }
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button variant="contained" type="submit">
                        변경하기
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          </form>
       )}
      </Formik>
      </Grid>
)
};

export default MyPageSetting;