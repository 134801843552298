
export const bankArray = [
    "농협은행",
    "KB 국민은행",
    "우리은행",
    "신한은행",
    "기업은행",
    "KEB 하나은행",
    "우체국",
    "SC 제일은행",
    "대구은행",
    "씨티은행",
    "부산은행",
    "경남은행",
    "광주은행",
    "전북은행",
    "새마을금고",
    "KDB산업은행",
    "수협은행",
    "제주은행",
    "신협",
    "케이뱅크 "
];