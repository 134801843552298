import {useCallback, useEffect, useState} from "react";
import {getSelectedState, Grid, GridColumn} from "@progress/kendo-react-grid";
import {Button, Grid as MuiGrid, MenuItem, OutlinedInput, Select, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import { getter } from "@progress/kendo-react-common";
import Axios from "../../axios/Axios";
import {useDispatch} from "react-redux";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import dayjs from "dayjs";


const TeacherList = () => {
    const [category , setCategory] = useState("id");
    const [keyword , setKeyword] = useState("");
    const [teacherList , setTeacherList ] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    /**
     *  REST 요청 카테고리 및 상태 변경
     * @param event
     */
    const handleChange = (event) => {
        const value = event.target.value;
        setCategory(value)
    };

    /**
     * 검색 키워드
     * @param event
     */
    const keywordHandleChange = (event) => {
        setKeyword(event.target.value);
    }

    /**
     * 페이지 처리
     * @type {{take: number, skip: number}}
     */
    let param = {
        skip: 0,
        take: 10
    }

    const [page, setPage] = useState(param);

    /**
     * 페이지 변경
     * @param event
     */
    const pageChange = (event) => {
        setPage(event.page);
    };

    /**
     * REST 요청 키워드
     * @type {{category: string, keyword: string}}
     */
    let categorySelect = {
        category: category,
        keyword: keyword
    }
    /**
     * 카테고리 검색히 다시 페이지 1로 초기화
     */
    const categorySelectFunction = () => {
        axiosForm();
        page.skip = 0;
        setPage(page);
    }

    let count = (function (){
        let sum =0;
        return function a () {
            return ++sum;
        };
    })();

    const enterFunction = (event) => {
        if(event.keyCode === 13){
            categorySelectFunction()
        }
    }

    const DATA_ITEM_KEY = "id";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const [selectedState, setSelectedState] = useState({});

    /**
     * 한개 선택
     * @type {(function(*): void)|*}
     */
    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    /**
     * 전체 선택
     * @type {(function(*): void)|*}
     */
    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked; // 눌려있는가 체크
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked; // 체크로 변환
        });
        setSelectedState(newSelectedState);
    }, []);

    /**
     * 강사 선택 , 삭제
     */
    const selectTeacherDeleteFunction = () => {
        const selectObject = selectedState;
        const ids = Object.keys(selectObject);
        const values = Object.values(selectObject);

        if(values.length !== ids.length) throw new Error("유효하지 않은 요청입니다.")

        let changeTeacherList = [];
        values.map( (valueBoolean , i ) => {
            if(valueBoolean === true){
                const changeTeacher = ids.at(i);
                changeTeacherList.push(changeTeacher);
            }
        })

        dispatch(
            showAlert({
                items: 2,
                title: "삭제하시겠습니까?",
                message: "삭제하시겠습니까?",
                text1: '확인',
                text2: '취소',
                sucCallback: () => {
                    deleteByTeacher(changeTeacherList);
                }
            }),
        );
    }

    const deleteByTeacher = (changeTeacherList) => {
        Axios.delete("/cms/teacher/delete" , changeTeacherList , false).then(result => {
            dispatch(
                showAlert({
                    items: 1,
                    title: '탈퇴 요청',
                    message: '정상적으로 탈퇴 되었습니다.',
                    text1: '닫기'
                }),
            );
            axiosForm();
        })
    }

    /**
     * axios
     */
    let axiosForm = () => {
        Axios.get("/cms/teacher/list" ,  categorySelect).then(result => {
                const resultTeacherList = result.data.list;
                let copy = [...teacherList];
                copy = resultTeacherList.map((teacher) => ({
                    idx: count(),
                    id: teacher.id,
                    email: teacher.email,
                    name: teacher.name,
                    phone_number: teacher.phone_number,
                    reg_date: dayjs(teacher.reg_date).format('YYYY-MM-DD')
                }));
                copy.reverse()
                setTeacherList(copy);
            }
        )
    }

    useEffect( () => {
        axiosForm();
     } , [])

    return (
        <>
            <MuiGrid container spacing={3} rowSpacing={1} marginBottom={10}>
                <MuiGrid item sx={{ width: '30%'}}>
                    <Select
                        fullWidth
                        labelId="select-label"
                        id="simple-select"
                        value={category}
                        onChange={handleChange}
                    >
                        <MenuItem value={'id'}>아이디</MenuItem>
                        <MenuItem value={'name'}>이름</MenuItem>
                        <MenuItem value={'email'}>이메일</MenuItem>
                    </Select>

                </MuiGrid>
                <MuiGrid item sx={{ width: '55%'}}>
                    <OutlinedInput
                        fullWidth
                        placeholder="검색어를 입력해주세요."
                        onChange={keywordHandleChange}
                        onKeyDown={enterFunction}
                    />
                </MuiGrid>
                <MuiGrid item sx={{ width: '15%' }}>
                    <Button  type="button" onClick={() => {
                            categorySelectFunction()
                                }} >검색</Button>
                </MuiGrid>
            </MuiGrid>
            <MuiGrid item sx={{ width: '100%'}}>
                <Typography  style={{float:"right" , marginRight:2}} variant="h6">총 {teacherList.length} 건</Typography>
            </MuiGrid>
            <Grid
                style={{ height: "400px" }}

                data={
                    teacherList.slice(page.skip , page.take+ page.skip).map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))
                }
                pageable={{ buttonCount: 3 }}
                onPageChange={pageChange}
                skip={page.skip}
                take={page.take}
                total={teacherList.length}
                expandField="expanded"

                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                }}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
            >
                <GridColumn
                    field={SELECTED_FIELD}
                    width="50"
                    // headerSelectionValue={
                    //     // dataState.findIndex((item) => !selectedState[idGetter(item)]) === -1
                    // } // 헤더 선택 확인란의 선택 여부를 정의합니다.
                />
                <GridColumn field="idx" title="번호" width="100" />
                <GridColumn field="id" title="아이디" width="150" cell={TeacherDetailHref}/>
                <GridColumn field="name" title="이름" width="160"/>
                <GridColumn field="email" title="이메일" style={{width:"50%"}} />
                <GridColumn field="phone_number" title="휴대폰번호" width="160"/>
                <GridColumn field="reg_date" title="가입일" style={{width:"15%"}}/>
            </Grid>
            <MuiGrid item xs={6}>
                <Button type="button" style={{float: 'left' , marginTop: 10}} width={40} variant="contained" onClick={()=> {
                        try {
                            selectTeacherDeleteFunction()
                        } catch (e) {
                            dispatch(
                                showAlert({
                                    items: 1,
                                    title: "실패",
                                    message: e.message,
                                    text1: '확인',
                                }),
                            );
                        }
                    }
                }
                >강사 삭제</Button>
                <Button type="button" style={{float: 'right' , marginTop: 10}} width={40} variant="contained" onClick={()=> navigate("/cms/teacherManage/teacherRegister/admin")}>강사 등록</Button>
            </MuiGrid>
        </>
    )
}

const TeacherDetailHref = (props) => {
    return (
        <td>
            <Link to={`/cms/teacherManage/teacherDetail/admin/${props.dataItem.id}`} state={{ id: props.dataItem.id }}>{props.dataItem.id}</Link>
        </td>
    )

}

export default TeacherList;