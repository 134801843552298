import TeacherLogin from "pages/auth/TeacherLogin";
import AdminLogin from "../pages/auth/AdminLogin";

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes =
   {
    path: '/login',
    children: [{
       path: 'teacher',
        element: <TeacherLogin />,
       } ,
       {
         path: 'admin',
         element: <AdminLogin />
       }
     ]
  };

export default LoginRoutes;
