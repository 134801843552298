import useAuth from "../../hooks/useAuth";
import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {checkList, duplicateUserEmailCheck} from "./userAxios";
import {
    Button, Checkbox, Divider,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {regEmail, regNumber, regPassword, regPhoneNumber} from "../../utils/reg";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import Axios from "../../axios/Axios";
import MainCard from "../../components/MainCard";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {SelectTeacherDialog} from "./SelectTeacherDialog";

const UserDetailTeacher = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [member, setMember] = useState({
        email: "",
        name: "",
        age: 0,
        phone_number: "",
        curriculum_day: "",
        curriculum_time: ""
    })
    // location 에서 프롭스 값 들고 오기
    const location = useLocation();
    const email = location.state.email;
    const param = {
        email: email
    }
    useEffect(() => {
        Axios.get( "/teacher/user/detail", param , false ).then(result=>{
            setMember(result.user)
        })
    }, [])
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} md={12}>
                <MainCard title="회원 상세 정보">
                    <Grid container spacing={4}>
                        <Grid item sx={{ width: '100%' }} >
                            <InputLabel htmlFor="email">이메일</InputLabel>
                            <OutlinedInput
                                fullWidth
                                id="email"
                                name="email"
                                value={member.email}
                                readOnly={true}
                                style={{fontWeight:"bold"}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="name">학습자 이름</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    id="name"
                                    name="name"
                                    value={member.name}
                                    type="text"
                                    style={{fontWeight:"bold"}}
                                    readOnly={true}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="phone_number">휴대폰 번호</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    id="phone_number"
                                    name="phone_number"
                                    value={member.phone_number}
                                    type="text"
                                    style={{fontWeight:"bold"}}
                                    readOnly={true}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="age">학습자 나이</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    id="age"
                                    name="age"
                                    value={member.age}
                                    type="text"
                                    style={{fontWeight:"bold"}}
                                    readOnly={true}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="curriculum_day">수강 일</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    id="curriculum_day"
                                    name="curriculum_day"
                                    value={member.curriculum_day != null ? member.curriculum_day : "수강중이 아닙니다."}
                                    type="text"
                                    style={{fontWeight:"bold"}}
                                    readOnly={true}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="curriculum_time">수업 시간</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    id="curriculum_time"
                                    name="curriculum_time"
                                    value={member.curriculum_time != null ? member.curriculum_time : "수강중이 아닙니다."}
                                    type="text"
                                    style={{fontWeight:"bold"}}
                                    readOnly={true}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
        </Grid>

    )
}

export default UserDetailTeacher;