import {
    Button,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    OutlinedInput, Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {regNumber} from "../../../utils/reg";
import Axios from "../../../axios/Axios";
import {showAlert} from "../../../store/reducers/alertDialogSlideRedux";
import MainCard from "../../../components/MainCard";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {useEffect} from "react";


const CurriculumDayDetail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [curriculumUsed , setCurriculumUsed] = useState("Y");

    const [curriculumDay , setCurriculumDay] = useState({
        curriculum_day: "",
        price: "",
        reg_date: "",
        used: "Y"
    })


    const location = useLocation();
    const idx = location.state.idx;

    const handleRadio = (e) => {
        setCurriculumUsed(e.target.value);
    }

    useEffect(()=> {
        const params = {
            idx: idx
        }
        Axios.get("/cms/curriculum_day/detail" , params , false).then(result => {

            if(result.data != null){
                setCurriculumDay(result.data);
                setCurriculumUsed(result.data.used)
            } else {
                dispatch(
                    showAlert({
                        items: 1,
                        title: "실패",
                        message: '잘못된 접근입니다.',
                        text1: '확인',
                    }),
                );
            }
        })
    },[])


    const deleteByCurriculumDay = () => {
        const changeCurriculumDayList = [];
        changeCurriculumDayList.push(idx);
        Axios.delete("/cms/curriculum_day/delete" , changeCurriculumDayList , false).then(result => {
            dispatch(
                showAlert({
                    items: 1,
                    title: '수강 일 삭제 요청',
                    message: '정상적으로 삭제 되었습니다.',
                    text1: '닫기',
                    sucCallback: ()=> {
                        navigate("/cms/setting/curriculumDay/admin")
                    }
                }),
            );
        })
    }

    return (
        <Grid container spacing={2} >
            <Formik
                initialValues={{
                    curriculum_day: curriculumDay.curriculum_day,
                    curriculum_price: curriculumDay.price,
                    curriculum_rank: curriculumDay.rank,
                    submit: null
                }}
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={Yup.object().shape({
                    curriculum_price:  Yup.string().max(255).matches(regNumber , "숫자만 입력 가능합니다.").required('수강 금액을 입력해주세요.'),
                    curriculum_day: Yup.string().max(255).required('수강 일을 입력해주세요.'),
                    curriculum_rank: Yup.string().max(255).matches(regNumber , "숫자만 입력 가능합니다.").required('숫자로 입력해주세요.')
                })}
                onSubmit={(values, actions) => {
                    const data = new FormData();
                    data.append("curriculum_day" , values.curriculum_day);
                    data.append("price" , values.curriculum_price);
                    data.append("used" , curriculumUsed);
                    data.append("idx" , idx);
                    data.append("rank" , values.curriculum_rank);
                    Axios.patch("/cms/curriculum_day/modify" , data , true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "성공",
                                message: '수강일 변경에 성공하였습니다.',
                                text1: '확인',
                                sucCallback: ()=> {
                                    navigate("/cms/setting/curriculumDay/admin")
                                }
                            }),
                        );
                    });
                }}
                enableReinitialize
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="수강일 상세 정보">
                                <Grid container spacing={4}>
                                    <Grid item sx={{ width: '100%' }} >
                                        <InputLabel htmlFor="curriculum_day">수강일</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="curriculum_day"
                                            name="curriculum_day"
                                            value={values.curriculum_day}
                                            onChange={ (e) => {handleChange(e)}}
                                            placeholder="수강 일을 입력해주세요."
                                            style={{fontWeight:"bold"}}
                                            error={Boolean(( errors.curriculum_day))}
                                        />
                                        { ( errors.curriculum_day) && (
                                            <FormHelperText error id="standard-weight-helper-text-curriculum-day">
                                                {errors.curriculum_day}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '80%' }}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="curriculum_price">금액</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="curriculum_price"
                                                name="curriculum_price"
                                                value={values.curriculum_price}
                                                type="text"
                                                placeholder="금액을 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                onChange={handleChange}
                                                error={Boolean(  errors.curriculum_price)}
                                            />
                                        </Stack>
                                        { errors.curriculum_price && (
                                            <FormHelperText error id="standard-weight-helper-text-curriculum-price">
                                                {errors.curriculum_price}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '20%' ,  marginTop:5 , marginLeft: -2}}>
                                        <Typography variant="h4" color="secondary" >원</Typography>
                                    </Grid>
                                    <Grid item sx={{ width: '80%' }}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="curriculum_rank">순서</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="curriculum_rank"
                                                name="curriculum_rank"
                                                value={values.curriculum_rank}
                                                type="text"
                                                placeholder="금액을 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                onChange={handleChange}
                                                error={Boolean(  errors.curriculum_rank)}
                                            />
                                        </Stack>
                                        { errors.curriculum_rank && (
                                            <FormHelperText error id="standard-weight-helper-text-curriculum_rank">
                                                {errors.curriculum_rank}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel id="demo-radio-buttons-group-label">사용 여부</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={curriculumUsed}
                                            name="used-radio-group"
                                            onChange={ (e) => {
                                                handleRadio(e)
                                            }}
                                        >
                                            <FormControlLabel value="Y" control={<Radio size="medium"/>} label="사용" />
                                            <FormControlLabel value="N" control={<Radio size="medium"/>} label="사용 안함" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item sx={{ width: '100%' }} >
                                        <InputLabel htmlFor="reg_date">등록일</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="reg_date"
                                            name="reg_date"
                                            value={curriculumDay.reg_date}
                                            readOnly={true}
                                            style={{fontWeight:"bold"}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="button" style={{float: 'left' , marginTop: 10}} width={40} variant="contained" onClick={()=> {
                                            try {
                                                deleteByCurriculumDay()
                                            } catch (e) {
                                                dispatch(
                                                    showAlert({
                                                        items: 1,
                                                        title: "실패",
                                                        message: e.message,
                                                        text1: '확인',
                                                    }),
                                                );
                                            }
                                        }
                                        }
                                        >삭제</Button>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} width={40} type="button" onClick={()=>   navigate("/cms/setting/curriculumDay/admin")}>
                                            취소
                                        </Button>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} type="submit">
                                            수정
                                        </Button>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    );
}

export default CurriculumDayDetail;