import {
    Button,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import Axios from "../../axios/Axios";
import MainCard from "../../components/MainCard";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import useAuth from "../../hooks/useAuth";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {duplicateEmailCheck} from "./teacherAxiosRequest";
import {regEmail, regPassword, regPhoneNumber} from "../../utils/reg";
import dayjs from "dayjs";


const TeacherDetail = () =>  {
    const {user} = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const [duplicateEmail , setDuplicateEmail] = useState(false);
    const dispatch = useDispatch();
    let response = {
        id: "",
        name: "",
        email: "",
        phone_number: "",
        reg_date: ""
    }
    const [teacher , setTeacher] = useState(response)
    const location = useLocation();
    const id = location.state.id;
    const navigate = useNavigate();
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailOnChange= (e) => {
        setDuplicateEmail(true);
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    /**
     * 강사 선택 , 삭제
     */
    const teacherDeleteFunction = (id) => {
        const deleteTeacher = [id];
        dispatch(
            showAlert({
                items: 2,
                title: "삭제하시겠습니까?",
                message: "삭제하시겠습니까?",
                text1: '확인',
                text2: '취소',
                sucCallback: () => {
                    deleteByTeacher(deleteTeacher);
                }
            }),
        );
    }

    const deleteByTeacher = (changeTeacherList) => {
        Axios.delete("/cms/teacher/delete" , changeTeacherList , false).then(result => {
            dispatch(
                showAlert({
                    items: 1,
                    title: '탈퇴 요청',
                    message: '정상적으로 탈퇴 되었습니다.',
                    text1: '닫기'
                }),
            );
        })
        setTimeout(() => {
            navigate("/cms/teacherManage/teacherList/admin");
        }, 1000)
    }

    useEffect( () => {
        const params = {
            id: id
        }
        Axios.get("/cms/teacher/detail" , params , false).then(result => {
            const teacherResult = result.data;
            setTeacher(teacherResult)
        })
    } , [])

    return (
        <Grid container spacing={2} >
            <Formik
                initialValues={
                    user.role === 1 && {
                        id: teacher.id,
                        name: teacher.name,
                        email: teacher.email,
                        phoneNumber: teacher.phone_number,
                        password: "",
                        reg_date: dayjs(teacher.reg_date).format('YYYY-MM-DD'),
                        submit: null
                    }
                }
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={
                    Yup.object().shape(
                        user.role === 1 && {
                            name: Yup.string().max(255).required('이름을 입력해주세요.'),
                            email: Yup.string().max(255).matches(regEmail , "이메일 형식을 입력해 주세요.").required('이메일을 입력해주세요.'),
                            phoneNumber:  Yup.string().max(255).matches(regPhoneNumber , "숫자만 입력 가능합니다.").required('휴대폰번호를 입력해주세요.')
                        }
                    )
                }

                onSubmit={(values, actions) => {

                    if(teacher.email !== values.email) {
                        if (duplicateEmail) {
                            dispatch(
                                showAlert({
                                    items: 1,
                                    title: "이메일 중복검사 실패",
                                    message: "입력 값이 올바르지 않습니다.",
                                    text1: '확인',
                                }),
                            );
                            throw new Error("중복검사를 진행해 주세요.");
                        }
                    }



                    const data = new FormData();
                    data.append("id" , values.id);
                    data.append("name" , values.name);
                    data.append("email" , values.email);

                    if(values.password !== "") {
                        if(!regPassword.test(values.password)){
                            dispatch(
                                showAlert({
                                    items: 1,
                                    title: "비밀번호 변경 실패",
                                    message: "입력 값이 올바르지 않습니다.",
                                    text1: '확인',
                                }),
                            );
                            throw new Error("비밀번호 변경 실패");
                        }
                        data.append("password", values.password);
                    }
                    data.append("phone_number" , values.phoneNumber);

                    Axios.patch("/cms/teacher/modify" , data , true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "성공",
                                message: "회원 정보 수정 완료",
                                text1: '확인',
                            }),
                        );
                    })
                }}
                enableReinitialize
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="강사 상세 정보">
                                <Grid container spacing={4} rowSpacing={1}>
                                    <Grid item sx={{ width: '100%' }} >
                                        <InputLabel htmlFor="id">아이디</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="id"
                                            name="id"
                                            value={values.id}
                                            style={{fontWeight:"bold"}}
                                            readOnly={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="name">이름</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="name"
                                                name="name"
                                                value={values.name}
                                                type="text"
                                                placeholder="이름을 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean( errors.name)}
                                            />
                                        </Stack>
                                        { errors.name && (
                                            <FormHelperText error id="standard-weight-helper-text-name">
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*구분 div 역할 세로 일경우에  direction="row"  divider={<Divider orientation="vertical" flexItem />} 다이버 설정가능 spacing = 간격 */}
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="password">비밀번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={values.password}
                                                onBlur={handleBlur} // 값 검증시 아무것도 없으면 이벤트
                                                onChange={handleChange}
                                                placeholder="비밀번호를 입력해주세요."
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            color="secondary"
                                                        >
                                                            {/* 아이콘 보여줄 ui 가져옴 눈표시 */}
                                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                error={Boolean(touched.password && errors.password)}
                                            />
                                        </Stack>
                                        {touched.password && errors.password && (
                                            <FormHelperText error id="standard-weight-helper-text-password">
                                                {errors.password}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '80%' }} >
                                        <InputLabel htmlFor="email">이메일</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="email"
                                            name="email"
                                            value={values.email}
                                            placeholder="이메일을 입력해주세요."
                                            style={{fontWeight:"bold"}}
                                            color={duplicateEmail ? 'warning' : 'primary'}
                                            type="email"
                                            onBlur={handleBlur}
                                            onChange={ (e) => {
                                                handleChange(e)
                                                handleEmailOnChange(e)
                                            }}
                                            error={Boolean(errors.email && duplicateEmail)} // true 일때 빨간색으로 됨.
                                        />
                                        { (errors.email && duplicateEmail) && (
                                            <FormHelperText error id="standard-weight-helper-text-email">
                                                {errors.email} 사용할 수 없는 이메일입니다.
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '20%'  , marginTop: '24px' }} >
                                        <Button fullWidth type="button" onClick={ () => {
                                            try {
                                                duplicateEmailCheck(values.email , dispatch , setDuplicateEmail , values.id)
                                            } catch (e) {
                                                dispatch(
                                                    showAlert({
                                                        items: 1,
                                                        title: "실패",
                                                        message: e.message,
                                                        text1: '확인',
                                                    }),
                                                );
                                            }
                                        }}>
                                            이메일 중복 검사
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="phone_number">휴대폰 번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="phone_number"
                                                name="phoneNumber"
                                                value={values.phoneNumber}
                                                type="text"
                                                placeholder="휴대폰번호를 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(errors.phoneNumber)}
                                            />
                                        </Stack>
                                        { errors.phoneNumber && (
                                            <FormHelperText error id="standard-weight-helper-text-phoneNumber">
                                                {errors.phoneNumber}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="reg_date">등록일</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="reg_date"
                                                name="reg_date"
                                                value={values.reg_date}
                                                type="text"
                                                style={{fontWeight:"bold"}}
                                                readOnly={true}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                            <Button type="button" style={{float: 'left' , marginTop: 10}} width={40} variant="contained" onClick={()=> {
                                                try {
                                                    teacherDeleteFunction(values.id)
                                                } catch (e) {
                                                    dispatch(
                                                        showAlert({
                                                            items: 1,
                                                            title: "실패",
                                                            message: e.message,
                                                            text1: '확인',
                                                        }),
                                                    );
                                                }
                                            }}>삭제</Button>
                                            <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} width={40} type="button" onClick={()=>   navigate("/cms/teacherManage/teacherList/admin")}>
                                                취소
                                            </Button>
                                            <Button variant="contained" style={{float: 'right' , marginTop: 10}} width={40} type="submit">
                                                수정
                                            </Button>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    )
}

export default TeacherDetail;