import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";


const MissingPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const locationArray = location.pathname.split("/");

    useEffect( () => {
        locationArray.includes("admin") ? navigate("/login/admin") : navigate("/login/teacher")
    }, [])

    return null;
}


export default MissingPage;