import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import {store} from "./store";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "contexts/ConfigContext";

// kendo-ui
import '@progress/kendo-theme-default/dist/all.css'
import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import AlertDialog from "./utils/AlertDialog";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

const persist = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persist}>
            <ConfigProvider>
              <BrowserRouter>
                  <AlertDialog/>
                  <App />
              </BrowserRouter>
            </ConfigProvider>
          </PersistGate>
      </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
