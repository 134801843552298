// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import type from './type';
import authToken from './authToken';
import alertDialogSlideRedux from './alertDialogSlideRedux';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// ==============================|| COMBINE REDUCERS ||============================== //

const persistConfig = {
  key: "root",
  storage, // localStorage 에 저장하기.
  whitelist: ["authToken"]   // menu, type , alertDialogSlideRedux , authToken 4개의 reducer 중에 auth reducer 만 localstorage 에 저장합니다.
  // blacklist -> 그것만 제외합니다
};

const reducers = combineReducers({
  menu, type , alertDialogSlideRedux , authToken
});

export default persistReducer(persistConfig, reducers);
