import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import Axios from "../../axios/Axios";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import {
    Button, Checkbox, Divider,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {regEmail, regNumber, regPassword, regPhoneNumber} from "../../utils/reg";
import MainCard from "../../components/MainCard";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {SelectTeacherDialog} from "./SelectTeacherDialog";
import {duplicateUserEmailCheck , checkList} from "./userAxios";
import useAuth from "../../hooks/useAuth";

const UserDetail = () => {

    const {user} = useAuth();
    const [duplicateEmail , setDuplicateEmail] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // location 에서 프롭스 값 들고 오기
    const location = useLocation();
    const email = location.state.email;
    const [name ,setName] = useState("");


    const [checked , setChecked] = useState([false , false, false, false]);
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    const [detailUser , setDetailUser] = useState({
        email: "",
        name: "",
        password: "",
        phone_number: "",
        age: "",
        step: checked,
        teacher: "",

    });
    const teacherRef = useRef()
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEmailOnChange= (e) => {
        setDuplicateEmail(true);
    }

    const deleteUser = () => {
        dispatch(
            showAlert({
                items: 2,
                title: "삭제하시겠습니까?",
                message: "삭제하시겠습니까?",
                text1: '확인',
                text2: '취소',
                sucCallback: () => {
                    Axios.delete("/cms/user/delete" , [email], false).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "확인",
                                message: "정상적으로 탈퇴 되었습니다.",
                                text1: '확인',
                                sucCallback: ()=> {
                                     navigate('/cms/user/userList/admin')
                                }
                            })
                        )
                    })
                }
            }),
        );

    }
    useEffect(() => {
        const param ={
            email: email
        }
        Axios.get("/cms/user/detail" , param , false).then(result=> {
            setDetailUser(result.user);
            setName(result.user.teacher)
            const step = result.user.step.split(",");
            let copyList = [...checked];
            //step === 1 ? copyList[0] = true : step === 2 ? copyList[1] = true : step === 3 ? copyList[2] = true : step === 4 ?  copyList[3] = true : null;
            step.map(steps => {
                switch (steps) {
                    case "step1" : {
                        copyList[0] = true;
                        break;
                    }
                    case "step2" : {
                        copyList[1] = true;
                        break;
                    }
                    case "step3" : {
                        copyList[2] = true;
                        break;
                    }
                    case "step4" : {
                        copyList[3] = true;
                        break;
                    }
                }
            })
            setChecked(copyList);
        })
    }, [])

    const handleCheckList = (e) => {
        const checkId = e.target.id.toString();
        const checkIndex = Number(checkId.slice(-1));
        let copyList = [...checked];
        copyList[checkIndex] = e.target.checked;
        setChecked(copyList);
    }

    return (
        <Grid container spacing={2} >
            <Formik
                initialValues={{
                    email: detailUser.email,
                    name: detailUser.name,
                    password: "",
                    phone_number: detailUser.phone_number,
                    age: detailUser.age,
                    step: checked ,
                    teacher: detailUser.teacher,
                    code: detailUser.code,
                    submit: null
                }}
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('이름을 입력해주세요.'),
                    phone_number:  Yup.string().max(255).matches(regPhoneNumber , "숫자만 입력 가능합니다.").required('휴대폰번호를 입력해주세요.'),
                    age: Yup.string().max(255).matches(regNumber , "숫자만 입력 가능합니다.").required('나이를 입력해주세요.'),
                })}
                onSubmit={(values, actions) => {

                    values.step = checked;
                    // const teacherNameList = teacherRef.current.children[0].value;
                    const teacherNameList = selectedValue;
                    const data = {
                        ...values,
                        teacher: teacherNameList.length === 0 ? detailUser.teacher : teacherNameList
                    }
                    Axios.patch("/cms/user/modify" , data , true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "확인",
                                message: "정상적으로 수정되었습니다.",
                                text1: '확인',
                                sucCallback: ()=> {
                                    navigate('/cms/user/userList/admin')
                                }
                            })
                        )
                    })
                }}
                enableReinitialize
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="회원 상세 정보">
                                <Grid container spacing={4}>
                                    <Grid item sx={{ width: '100%' }} >
                                        <InputLabel htmlFor="email">이메일</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="email"
                                            name="email"
                                            value={values.email}
                                            readOnly={true}
                                            style={{fontWeight:"bold"}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*구분 div 역할 세로 일경우에  direction="row"  divider={<Divider orientation="vertical" flexItem />} 다이버 설정가능 spacing = 간격 */}
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="password">비밀번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={values.password}
                                                onBlur={handleBlur} // 값 검증시 아무것도 없으면 이벤트
                                                onChange={handleChange}
                                                placeholder="비밀번호를 입력해주세요."
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            color="secondary"
                                                        >
                                                            {/* 아이콘 보여줄 ui 가져옴 눈표시 */}
                                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                error={Boolean(touched.password && errors.password)}
                                            />
                                        </Stack>
                                        {touched.password && errors.password && (
                                            <FormHelperText error id="standard-weight-helper-text-password">
                                                {errors.password}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="name">학습자 이름</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="name"
                                                name="name"
                                                value={values.name}
                                                type="text"
                                                placeholder="학습자 이름을 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean( touched.name && errors.name)}
                                            />
                                        </Stack>
                                        { touched.name && errors.name && (
                                            <FormHelperText error id="standard-weight-helper-text-name">
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="phone_number">휴대폰 번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="phone_number"
                                                name="phone_number"
                                                value={values.phone_number}
                                                type="text"
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                placeholder="휴대폰 번호를 입력해주세요."
                                                onChange={handleChange}
                                                error={Boolean(touched.phone_number && errors.phone_number)}
                                            />
                                        </Stack>
                                        {
                                            touched.phone_number && errors.phone_number && (
                                                <FormHelperText error id="standard-weight-helper-text-phone_number">
                                                    {errors.phone_number}
                                                </FormHelperText>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="age">학습자 나이</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="age"
                                                name="age"
                                                value={values.age}
                                                type="text"
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                placeholder="학습자의 '만 나이'를 입력해주세요."
                                                onChange={handleChange}
                                                error={Boolean(touched.age && errors.age)}
                                            />
                                        </Stack>
                                        {
                                            touched.age && errors.age && (
                                                <FormHelperText error id="standard-weight-helper-text-age">
                                                    {errors.age}
                                                </FormHelperText>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="step-stack" style={{marginBottom:10}}>단계 선택</InputLabel>
                                        <Stack id="step-stack" direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                                            <Grid width={"25%"} textAlign={"center"}>
                                                <InputLabel htmlFor="step0">step1</InputLabel>
                                                <Checkbox
                                                    checked={checked[0]}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleCheckList(e)
                                                    }}
                                                    id="step0"
                                                    name="step"
                                                    value={values.step[0]}
                                                />
                                            </Grid>
                                            <Grid width={"25%"} textAlign={"center"}>
                                                <InputLabel htmlFor="step1">step2</InputLabel>
                                                <Checkbox
                                                    checked={checked[1]}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleCheckList(e)
                                                    }}
                                                    id="step1"
                                                    name="step"
                                                    value={values.step[1]}
                                                />
                                            </Grid>
                                            <Grid width={"25%"} textAlign={"center"}>
                                                <InputLabel htmlFor="step2">step3</InputLabel>
                                                <Checkbox
                                                    checked={checked[2]}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleCheckList(e)
                                                    }}
                                                    id="step2"
                                                    name="step"
                                                    value={values.step[2]}
                                                />
                                            </Grid>
                                            <Grid width={"25%"} textAlign={"center"}>
                                                <InputLabel htmlFor="step3">step4</InputLabel>
                                                <Checkbox
                                                    checked={checked[3]}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleCheckList(e)
                                                    }}
                                                    id="step3"
                                                    name="step"
                                                    value={values.step[3]}
                                                />
                                            </Grid>
                                        </Stack>
                                    </Grid>

                                    <Grid item sx={{ width: '80%' }} >
                                        <InputLabel htmlFor="teacherName">담당 강사</InputLabel>
                                        <OutlinedInput  id="teacherName" name="teacherName" style={{fontWeight:"bold" , marginTop:10 }} fullWidth
                                                        ref={teacherRef}
                                                        value = { name != null ? name : ""} />

                                    </Grid>
                                    <Grid item sx={{ width: '20%'  , marginTop: '29px' }} >
                                        <Button fullWidth type="button" onClick={handleClickOpen}>
                                            강사 선택
                                        </Button>
                                    </Grid>
                                    <SelectTeacherDialog
                                        setSelectedValue = {setSelectedValue}
                                        open={open}
                                        onClose={
                                            handleClose
                                        }
                                        setName={setName}
                                    />
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="code">프로모션 코드</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="code"
                                                name="code"
                                                value={values.code}
                                                type="text"
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                placeholder="프로모션 코드를 입력해주세요."
                                                onChange={handleChange}
                                                error={Boolean(touched.code && errors.code)}
                                            />
                                        </Stack>
                                        {
                                            touched.code && errors.code && (
                                                <FormHelperText error id="standard-weight-helper-text-age">
                                                    {errors.code}
                                                </FormHelperText>
                                            )
                                        }
                                    </Grid>
                                    {user.role === 1 &&
                                        <Grid item xs={12}>
                                            <Button variant="contained" style={{float: 'left' , marginTop: 10 , marginLeft: 10}} type="button" onClick={()=> {deleteUser()}}>
                                                삭제
                                            </Button>
                                            <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} width={40} type="button" onClick={()=>   navigate("/cms/teacherManage/teacherList/admin")}>
                                                취소
                                            </Button>
                                            <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} type="submit">
                                                수정
                                            </Button>
                                        </Grid>
                                    }
                                    {
                                        user.role === 0 &&
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} width={40} type="button" onClick={()=>   navigate("/cms/teacherManage/teacherList/admin")}>
                                            목록
                                        </Button>
                                    }
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>

    )
}

export default UserDetail;