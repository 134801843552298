// third-party
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    authType: "",
    authName: ""
};

const type = createSlice({
    name: 'type',  // state 이름
    initialState,// state 값
    reducers: { // 수정하는 함수 // state -> 기존 스테이트 , 
        activeAuthType(state, action) {
            state.authType = action.payload.authType;
        },
        activeAuthName(state, action) {
            state.authName = action.payload.authName;
        }
    }
});

export default type.reducer;

export const { activeAuthType , activeAuthName} = type.actions;
