import ThemeCustomization from "./themes";
import Routes from "./routes";
import { MemberProvider as AuthProvider} from "./contexts/MemberContext";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {initData} from "./axios/Axios";

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // API Request 초기화
        initData(dispatch);
    }, []);

    return (
        <ThemeCustomization>
            <AuthProvider>
                <>
                    <Routes/>
                </>
            </AuthProvider>
        </ThemeCustomization>
    );
}

export default App;
