import { configureStore} from '@reduxjs/toolkit';

// project import
import reducers from './reducers';
import thunk from "redux-thunk";

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //
const store = configureStore({
  reducer: reducers,
  middleware: [thunk]
});

export { store };
