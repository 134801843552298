

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return (
    <img src="/rucy-max-user.png" alt="" style={{ width: 42 , backgroundSize: "cover"}}></img>
  );
};

export default LogoIcon;
