import {
    Button, Checkbox, Divider, FormControlLabel,
    FormHelperText, FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput, Radio, RadioGroup,
    Stack, Typography
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {regNumber} from "../../../utils/reg";
import {showAlert} from "../../../store/reducers/alertDialogSlideRedux";
import Axios from "../../../axios/Axios";
import MainCard from "../../../components/MainCard";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

const CurriculumDayRegistry = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [curriculumUsed , setCurriculumUsed] = useState("Y");

    const handleRadio = (e) => {
        setCurriculumUsed(e.target.value);
    }

    return (
        <Grid container spacing={2} >
            <Formik
                initialValues={{
                    curriculum_day: "",
                    curriculum_price: "",
                    rank: "",
                    submit: null
                }}
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={Yup.object().shape({
                    curriculum_price:  Yup.string().max(255).matches(regNumber , "숫자만 입력 가능합니다.").required('수강 금액을 입력해주세요.'),
                    curriculum_day: Yup.string().max(255).required('수강 일을 입력해주세요.'),
                    curriculum_rank: Yup.string().max(255).matches(regNumber , "숫자만 입력 가능합니다.").required('숫자로 입력해주세요.')
                })}
                onSubmit={(values, actions) => {
                    const data = new FormData();
                    data.append("curriculum_day" , values.curriculum_day);
                    data.append("price" , values.curriculum_price);
                    data.append("used" , curriculumUsed);
                    data.append("rank" , values.curriculum_rank);
                    Axios.post("/cms/curriculum_day/registry" , data , true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "성공",
                                message: '수강일 등록에 성공하였습니다.',
                                text1: '확인',
                                sucCallback: () => {
                                    navigate('/cms/setting/curriculumDay/admin')
                                }
                            }),
                        );
                    });
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="수강일 등록">
                                <Grid container spacing={4}>
                                    <Grid item sx={{ width: '100%' }} >
                                        <InputLabel htmlFor="curriculum_day">수강일</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="curriculum_day"
                                            name="curriculum_day"
                                            value={values.curriculum_day}
                                            onBlur={handleBlur}
                                            onChange={ (e) => {handleChange(e)}}
                                            placeholder="수강 일을 입력해주세요."
                                            style={{fontWeight:"bold"}}
                                            error={Boolean((touched.curriculum_day && errors.curriculum_day))}
                                        />
                                        { (touched.curriculum_day && errors.curriculum_day) && (
                                            <FormHelperText error id="standard-weight-helper-text-curriculum-day">
                                                {errors.curriculum_day}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '80%' }}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="curriculum_price">금액</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="curriculum_price"
                                                name="curriculum_price"
                                                value={values.curriculum_price}
                                                type="text"
                                                placeholder="금액을 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean( touched.curriculum_price && errors.curriculum_price)}
                                            />
                                        </Stack>
                                        { touched.curriculum_price && errors.curriculum_price && (
                                            <FormHelperText error id="standard-weight-helper-text-curriculum-price">
                                                {errors.curriculum_price}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '20%' ,  marginTop:5 , marginLeft: -2}}>
                                        <Typography variant="h4" color="secondary" >원</Typography>
                                    </Grid>
                                    <Grid item sx={{ width: '80%' }}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="curriculum_rank">순서</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="curriculum_rank"
                                                name="curriculum_rank"
                                                value={values.curriculum_rank}
                                                type="text"
                                                placeholder="순서를 입력해주세요."
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean( touched.curriculum_rank && errors.curriculum_rank)}
                                            />
                                        </Stack>
                                        { touched.curriculum_rank && errors.curriculum_rank && (
                                            <FormHelperText error id="standard-weight-helper-text-curriculum_rank">
                                                {errors.curriculum_rank}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel id="demo-radio-buttons-group-label">사용 여부</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Y"
                                            name="used-radio-group"
                                            onChange={ (e) => {
                                                handleRadio(e)
                                            }}
                                        >
                                            <FormControlLabel value="Y" control={<Radio size="medium"/>} label="사용" />
                                            <FormControlLabel value="N" control={<Radio size="medium"/>} label="사용 안함" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} width={40} type="button" onClick={()=>   navigate("/cms/setting/curriculumDay/admin")}>
                                            취소
                                        </Button>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} type="submit">
                                            등록
                                        </Button>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>

    );

}

export default CurriculumDayRegistry;