import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    Button, FormHelperText,
    Grid,
    InputLabel, MenuItem,
    OutlinedInput, Select,
    TextField, Typography
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import Axios from "../../axios/Axios";
import MainCard from "../../components/MainCard";
import {SelectUserDialog} from "./SelectUserDialog";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import {regNumber} from "../../utils/reg";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";

const HandlePaymentRegistry = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [curriculumDay , setCurriculumDay] = useState([]);
    const [curriculumTime , setCurriculumTime] = useState([]);

    const [curriculumTimeSelect , setCurriculumTimeSelect] = useState("");

    const [curriculumDaySelect , setCurriculumDaySelect] = useState("");

    const [startDate , setStartDate] = useState(dayjs(Date.now()));
    const [endDate , setEndDate] = useState(dayjs(Date.now()));
    const [price , setPrice] = useState(0);


    useEffect(() => {

        Axios.get("/cms/curriculum_day/list" , null , false).then(result => {
            let curriculumDayList = [];
            result.data.list.map( curriculumDay => {
                curriculumDayList.push(curriculumDay);
            })

            setCurriculumDay(curriculumDayList);
            setCurriculumDaySelect(result.data.list[0].curriculum_day)
        })
        Axios.get("/cms/curriculum_time/list" , null , false).then(result=>{
            let curriculumTimeList = [];

            result.data.list.map(curriculumTime => {
                curriculumTimeList.push(curriculumTime);
            });

            setCurriculumTime(curriculumTimeList);
            setCurriculumTimeSelect(result.data.list[0].curriculum_time)

        })
    }, [])
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleChangeTime = (event) => {
        const value = event.target.value;
        setCurriculumTimeSelect(value);
    }

    const handleChangeDay = (event) => {
        const value = event.target.value;
        setCurriculumDaySelect(value);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container spacing={2} >
            <Formik
                initialValues={{
                    startDate: startDate,
                    endDate: endDate,
                    price: price,
                    submit: null
                }}
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={Yup.object().shape({
                    price: Yup.string().matches(regNumber , "숫자만 입력해 주세요.").max(255).required('숫자로 입력해주세요.'),
                })}
                onSubmit={(values, actions) => {
                    if(selectedValue == null || selectedValue.length === 0) {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "등록 실패",
                                message: "회원을 등록해 주세요.",
                                text1: '확인',
                            }),
                        );
                        throw new Error('회원을 등록해 주세요.');
                    }
                    if(values.price <= 0 && !regNumber.test(values.price)){
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "등록 실패",
                                message: "가격을 입력해 주세요.",
                                text1: '확인',
                            }),
                        );
                        throw new Error('가격을 입력해 주세요.');
                    }

                    if(startDate.format("YYYY-MM-DD") >= endDate.format("YYYY-MM-DD")){
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "등록 실패",
                                message: "올바른 수강 기간을 선택해주세요.",
                                text1: '확인',
                            }),
                        );
                        throw new Error('올바른 수강 기간을 선택해주세요.');
                    }
                    const data = new FormData();
                    data.append("start_date" , startDate.format("YYYY-MM-DD"))
                    data.append("end_date" , endDate.format("YYYY-MM-DD"));
                    data.append( "user_email" , selectedValue);
                    data.append("curriculum_day" , curriculumDaySelect);
                    data.append("curriculum_time" , curriculumTimeSelect);
                    data.append("notes_payment" , "Y");
                    data.append("price" , values.price);

                    Axios.post("/cms/payment/notes/registry" , data , true).then(result=> {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "성공",
                                message: "수기 결제 등록에 성공하였습니다.",
                                text1: '확인',
                                sucCallback: ()=> {
                                    navigate("/cms/paymentManagement/paymentList/admin");
                                }
                            }),
                        );
                    })
                }}
                enableReinitialize
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="수기 결제 등록">
                                <Grid container spacing={4}>
                                    <Grid item sx={{ width: '80%' }} >
                                        <InputLabel htmlFor="userEmail">학습자</InputLabel>
                                        <OutlinedInput  id="userEmail" name="userEmail" style={{fontWeight:"bold" , marginTop:10 }} fullWidth
                                                        value = {selectedValue.length !== 0 ? selectedValue.map((result )=> {
                                                            return result;
                                                        }) : '등록된 회원이 없습니다.'} />

                                    </Grid>
                                    <Grid item sx={{ width: '20%'  , marginTop: '29px' }} >
                                        <Button fullWidth type="button" onClick={handleClickOpen}>
                                            학습자 등록
                                        </Button>
                                    </Grid>
                                    <SelectUserDialog
                                        setSelectedValue = {setSelectedValue}
                                        open={open}
                                        onClose={
                                            handleClose
                                        }
                                    />
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="curriculum_day_id">수강일</InputLabel>
                                        <Select
                                            fullWidth
                                            id="curriculum_day_id"
                                            name="curriculum_day_name"
                                            value={curriculumDaySelect}
                                            onChange={handleChangeDay}

                                        >
                                            {
                                                curriculumDay.length !== 0 && curriculumDay.map( (result , i) => {
                                                    return <MenuItem key={i} value={result.curriculum_day}>{result.curriculum_day}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="curriculum_time_id">수강시간</InputLabel>
                                        <Select
                                            fullWidth
                                            id="curriculum_time_id"
                                            name="curriculum_time_name"
                                            value={curriculumTimeSelect}
                                            onChange={handleChangeTime}
                                        >
                                            {
                                                curriculumTime.length !== 0 && curriculumTime.map( (result , i) => {
                                                    return <MenuItem key={i} value={result.curriculum_time}>{result.curriculum_time}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                    <Grid item sx={{ width: '50%' }}>
                                        <InputLabel htmlFor="price">결제금액</InputLabel>
                                        <OutlinedInput
                                            id="price"
                                            name="price"
                                            style={{fontWeight:"bold" , marginTop:10 }}
                                            fullWidth
                                            value={values.price}
                                            onBlur={handleBlur}
                                            onChange={ (e) => {
                                                handleChange(e)
                                                setPrice(e.target.value);
                                            }}
                                            placeholder="결제 금액을 입력해주세요."
                                            error={Boolean( (touched.price && errors.price ))}
                                        />
                                        { (touched.price && errors.price ) && (
                                            <FormHelperText error id="standard-weight-helper-text-price">
                                                {errors.price}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ width: '50%' , marginTop:5 , marginLeft: -2}}>
                                        <Typography style={{fontSize:20}}> 원</Typography>
                                    </Grid>
                                    <Grid item sx={{ width: '20%' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                                            <InputLabel htmlFor="start_date">시작일</InputLabel>
                                            <DatePicker
                                                id="start_date"
                                                value={values.startDate}
                                                onChange={(newValue) => {
                                                    setStartDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item sx={{ width: '4%' }}>
                                        <h1> - </h1>
                                    </Grid>
                                    <Grid item sx={{ width: '20%' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko" >
                                            <InputLabel htmlFor="end_date">종료일</InputLabel>
                                            <DatePicker
                                                id="end_date"
                                                value={values.endDate}
                                                onChange={(newValue) => {
                                                    setEndDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained"
                                                style={{float: 'right' , marginTop: 10 , marginLeft: 10}}
                                                width={40}
                                                type="button"
                                                onClick={()=>   navigate("/cms/paymentManagement/paymentList/admin")}>
                                            취소
                                        </Button>
                                        <Button variant="contained"
                                                style={{float: 'right' , marginTop: 10 , marginLeft: 10}}
                                                type="submit">
                                            등록
                                        </Button>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    )
}


export default HandlePaymentRegistry;