import {
    Button,
    FormHelperText,
    Grid, IconButton, InputAdornment,
    InputLabel, OutlinedInput,
    Stack
} from "@mui/material";

import MainCard from "../../components/MainCard";
import {Formik} from "formik";
import * as Yup from "yup";
import {useState} from "react";
import useAuth from "../../hooks/useAuth";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import Axios from "../../axios/Axios";
import {regPassword} from "../../utils/reg";

const MyPagePasswordSetting = () => {
    const {user} = useAuth();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <Grid container spacing={2}>
            <Formik
                initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    checkPassword: "",
                    submit: null
                }}
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={Yup.object().shape({
                   oldPassword: Yup.string().max(255).required('비밀번호를 입력해주세요.'),
                   newPassword: Yup.string().matches(regPassword , "9자리 이상 20자리 이하 영어,숫자,특수문자 를 포함하여야합니다.").max(255).required('비밀번호를 입력해주세요.'),
                   checkPassword: Yup.string().matches(regPassword , "9자리 이상 20자리 이하 영어,숫자,특수문자 를 포함하여야합니다.").max(255).required('비밀번호를 입력해주세요.')
                })}
                onSubmit={(values, actions) => {

                   if (values.newPassword !== values.checkPassword) {
                       dispatch(
                           showAlert({
                               items: 1,
                               title: "비밀번호 오류",
                               message: '새 비밀번호와 확인 비밀번호가 일치하지 않습니다.',
                               text1: '확인',
                           }),
                       );
                       throw new Error("비밀번호가 일치하지 않습니다.");
                   }
                    const data = new FormData();
                    data.append("oldPassword" , values.oldPassword);
                    data.append("newPassword" , values.newPassword);

                    user.role === 1 && Axios.patch("/cms/admin/my_modify_password" , data, true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "성공" ,
                                message: "정상적으로 변경되었습니다.",
                                text1: '확인',
                            }),
                        );
                    })


                    user.role === 0 && Axios.patch("/teacher/mypage/my_modify_password" , data, true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "성공" ,
                                message: "정상적으로 변경되었습니다.",
                                text1: '확인',
                            }),
                        );
                    })
                }}
                enableReinitialize
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="비밀번호 변경">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {/*구분 div 역할 세로 일경우에  direction="row"  divider={<Divider orientation="vertical" flexItem />} 다이버 설정가능 spacing = 간격 */}
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="oldPassword-login">현재 비밀번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="oldPassword-login"
                                                type='password'
                                                name="oldPassword"
                                                value={values.oldPassword}
                                                onBlur={(event) => {
                                                    handleBlur(event);
                                                }} // 값 검증시 아무것도 없으면 이벤트
                                                onChange={handleChange}
                                                placeholder="현재 비밀번호를 입력해주세요."
                                                error={Boolean(touched.oldPassword && errors.oldPassword)}
                                            />
                                        </Stack>
                                        {touched.oldPassword && errors.oldPassword && (
                                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                                {errors.oldPassword}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*구분 div 역할 세로 일경우에  direction="row"  divider={<Divider orientation="vertical" flexItem />} 다이버 설정가능 spacing = 간격 */}
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="newPassword-login">새 비밀번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="newPassword-login"
                                                type={showPassword ? 'text' : 'password'}
                                                name="newPassword"
                                                value={values.newPassword}
                                                onBlur={(event) => {
                                                    handleBlur(event);
                                                }} // 값 검증시 아무것도 없으면 이벤트
                                                onChange={handleChange}
                                                placeholder="변경하실 새 비밀번호를 입력해주세요."
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            color="secondary"
                                                        >
                                                            {/* 아이콘 보여줄 ui 가져옴 눈표시 */}
                                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                error={Boolean(touched.newPassword && errors.newPassword)}
                                            />
                                        </Stack>
                                        {touched.newPassword && errors.newPassword && (
                                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                                {errors.newPassword}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*구분 div 역할 세로 일경우에  direction="row"  divider={<Divider orientation="vertical" flexItem />} 다이버 설정가능 spacing = 간격 */}
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="checkPassword-login">확인 비밀번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="checkPassword-login"
                                                type={showPassword ? 'text' : 'password'}
                                                name="checkPassword"
                                                value={values.checkPassword}
                                                onBlur={(event) => {
                                                    handleBlur(event);
                                                }} // 값 검증시 아무것도 없으면 이벤트
                                                onChange={handleChange}
                                                placeholder="새 비밀 번호를 재 입력해주세요."
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            color="secondary"
                                                        >
                                                            {/* 아이콘 보여줄 ui 가져옴 눈표시 */}
                                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                error={Boolean(touched.checkPassword && errors.checkPassword)}
                                            />
                                        </Stack>
                                        {touched.checkPassword && errors.checkPassword && (
                                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                                {errors.checkPassword}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent="flex-end">
                                            <Button variant="contained" type="submit">
                                                변경하기
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    )
};

export default MyPagePasswordSetting;