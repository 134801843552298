

// ==============================|| AUTH ROUTING ||============================== //

import MissingPage from "../pages/auth/MissingPage";

const MissingRoutes =
    {
        path: '*',
        element: <MissingPage />
    };

export default MissingRoutes;
