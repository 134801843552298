import {Button, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {regEmail, regId, regName, regNumber, regPhoneNumber} from "../../utils/reg";
import MainCard from "../../components/MainCard";
import {useEffect, useState} from "react";
import {bankArray} from "./bankArray";
import Axios from "../../axios/Axios";
import {useDispatch} from "react-redux";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";

const AccountSetting = () => {
    const initValue = {
        accountName: "",
        accountNumber: "",
        bankName: "신한은행"
    }
    const [account , setAccount] = useState(initValue);
    const dispatch = useDispatch();

    useEffect(()=>{
        Axios.get("/cms/setting/bank/select" , null , false).then(result => {

            const accountResult = result.result;
            setAccount( {
                accountName: accountResult.account_name,
                accountNumber: accountResult.account_number,
                bankName: accountResult.bank_name
            })
        })
    },[])
    /**
     *  REST 요청 카테고리 및 상태 변경
     * @param event
     */
    const handleChanges = (event) => {
        const value = event.target.value;
        let copyAccount = {
            ...account,
            bankName: value
        }
        setAccount(copyAccount)
    };

    return (
        <Grid container spacing={2} >
            <Formik
                initialValues={
                    {
                        account_name: account.accountName,
                        account_number: account.accountNumber,
                        submit: null
                    }
                }
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={Yup.object().shape(
                    {
                        account_name: Yup.string().max(255).matches(regName , "특수문자는 사용 불가합니다.").required('예금주를 입력해주세요.'),
                        account_number: Yup.string().max(255).matches(regNumber , "숫자만 입력 가능합니다.").required('계좌번호를 입력해주세요.'),
                    }
                )}
                onSubmit={(values, actions) => {

                    const data = new FormData();
                    data.append("account_name" , values.account_name);
                    data.append("account_number" , values.account_number);
                    data.append("bank_name" , account.bankName);

                    Axios.post("/cms/setting/bank/save" , data , true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "정상",
                                message: "정상적으로 등록되었습니다.",
                                text1: '확인',
                            }),
                        );
                    })
                }}
                enableReinitialize
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="내 정보 설정">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="account_name">예금주</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="account_name"
                                                name="account_name"
                                                value={values.account_name}
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(errors.account_name)}
                                            />
                                        </Stack>
                                        { touched.account_name && errors.account_name && (
                                            <FormHelperText error id="standard-weight-helper-text-account-name">
                                                {errors.account_name}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="account_number">계좌번호</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="account_number"
                                                name="account_number"
                                                value={values.account_number}
                                                type="text"
                                                style={{fontWeight:"bold"}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(errors.account_number)}
                                            />
                                        </Stack>
                                        { touched.account_number && errors.account_number && (
                                            <FormHelperText error id="standard-weight-helper-text-account-number">
                                                {errors.account_number}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel id="demo-simple-select-label">은행명</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="bank_name"
                                                name="bank_name"
                                                value={account.bankName}
                                                label="Age"
                                                onChange={handleChanges}
                                            >
                                                {
                                                    bankArray.map( (result , i) => {
                                                        return <MenuItem key={i} value={result}>{result}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent="flex-end">
                                            <Button variant="contained" type="submit">
                                                수정
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    );
}

export default AccountSetting;