import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {getter} from "@progress/kendo-react-common";
import {useState ,useCallback, useEffect} from "react";
import {getSelectedState, Grid, GridColumn} from "@progress/kendo-react-grid";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import Axios from "../../axios/Axios";
import {Button, Grid as MuiGrid, MenuItem, OutlinedInput, Select} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import dayjs from "dayjs";


const UserList = () => {
    const {user} = useAuth();
    const [category , setCategory] = useState("email");
    const [keyword , setKeyword] = useState("");
    const [userList , setUserList ] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    /**
     *  REST 요청 카테고리 및 상태 변경
     * @param event
     */
    const handleChange = (event) => {
        const value = event.target.value;
        setCategory(value)
    };

    /**
     * 검색 키워드
     * @param event
     */
    const keywordHandleChange = (event) => {
        setKeyword(event.target.value);
    }

    /**
     * 페이지 처리
     * @type {{take: number, skip: number}}
     */
    let param = {
        skip: 0,
        take: 10
    }

    const [page, setPage] = useState(param);

    /**
     * 페이지 변경
     * @param event
     */
    const pageChange = (event) => {
        setPage(event.page);
    };

    /**
     * REST 요청 키워드
     * @type {{category: string, keyword: string}}
     */
    let categorySelect = {
        category: category,
        keyword: keyword
    }
    /**
     * 카테고리 검색히 다시 페이지 1로 초기화
     */
    const categorySelectFunction = () => {
        axiosForm();
        page.skip = 0;
        setPage(page);
    }

    let count = (function (){
        let sum =0;
        return function a () {
            return ++sum;
        };
    })();

    const enterFunction = (event) => {
        if(event.keyCode === 13){
            categorySelectFunction()
        }
    }

    const DATA_ITEM_KEY = "email";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const [selectedState, setSelectedState] = useState({});

    /**
     * 한개 선택
     * @type {(function(*): void)|*}
     */
    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    /**
     * 전체 선택
     * @type {(function(*): void)|*}
     */
    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked; // 눌려있는가 체크
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked; // 체크로 변환
        });
        setSelectedState(newSelectedState);
    }, []);

    /**
     * 회원 선택 , 삭제
     */
    const selectUserDeleteFunction = () => {
        const selectObject = selectedState;
        const ids = Object.keys(selectObject);
        const values = Object.values(selectObject);

        if(values.length !== ids.length) throw new Error("유효하지 않은 요청입니다.")

        let changeUserList = [];
        values.map( (valueBoolean , i ) => {
            if(valueBoolean === true){
                const changeUser = ids.at(i);
                changeUserList.push(changeUser);
            }
        })

        dispatch(
            showAlert({
                items: 2,
                title: "삭제하시겠습니까?",
                message: "삭제하시겠습니까?",
                text1: '확인',
                text2: '취소',
                sucCallback: () => {
                    deleteByUser(changeUserList);
                }
            }),
        );
    }

    const deleteByUser = (changeUserList) => {
        Axios.delete("/cms/user/delete" , changeUserList , false).then(result => {
            dispatch(
                showAlert({
                    items: 1,
                    title: '탈퇴 요청',
                    message: '정상적으로 탈퇴 되었습니다.',
                    text1: '닫기'
                }),
            );
            axiosForm();
        })
    }

    /**
     * axios
     */
    let axiosForm = () => {
       user.role === 1 && Axios.get("/cms/user/list" ,  categorySelect).then(result => {
                const resultTeacherList = result.data;
                let copy = [...userList];

                copy = resultTeacherList.map((user) => ({
                    idx: count(),
                    email: user.email,
                    name: user.name,
                    teacher: user.teacher,
                    age: user.age,
                    step: user.step,
                    reg_date: dayjs(user.reg_date).format('YYYY-MM-DD'),
                    code: user.code
                }));
                copy.reverse()
                setUserList(copy);
            }
        )

      user.role === 0 &&  Axios.get("/teacher/user/list" ,  categorySelect).then(result => {
              const resultTeacherList = result.data;
              let copy = [...userList];
              copy = resultTeacherList.map((user) => ({
                  idx: count(),
                  email: user.email,
                  name: user.name,
                  teacher: user.teacher,
                  age: user.age,
                  step: user.step,
                  reg_date: dayjs(user.reg_date).format('YYYY-MM-DD')
              }));
              copy.reverse()
              setUserList(copy);
          }
      )
    }

    useEffect( () => {
        axiosForm();
    } , [])


    return (
        <>
            <MuiGrid container spacing={3} rowSpacing={1} marginBottom={10}>
                <MuiGrid item sx={{ width: '30%'}}>
                    <Select
                        fullWidth
                        labelId="select-label"
                        id="simple-select"
                        value={category}
                        onChange={handleChange}
                    >
                        <MenuItem value={'email'}>이메일</MenuItem>
                        <MenuItem value={'name'}>이름</MenuItem>
                    </Select>

                </MuiGrid>
                <MuiGrid item sx={{ width: '55%'}}>
                    <OutlinedInput
                        fullWidth
                        placeholder="검색어를 입력해주세요."
                        onChange={keywordHandleChange}
                        onKeyDown={enterFunction}
                    />
                </MuiGrid>
                <MuiGrid item sx={{ width: '15%' }}>
                    <Button  type="button" onClick={() => {
                        categorySelectFunction()
                    }} >검색</Button>
                </MuiGrid>
            </MuiGrid>
            <Grid
                style={{ height: "400px" }}

                data={
                    userList.slice(page.skip , page.take+ page.skip).map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))
                }
                pageable={{ buttonCount: 3 }}
                onPageChange={pageChange}
                skip={page.skip}
                take={page.take}
                total={userList.length}
                expandField="expanded"

                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                }}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
            >
                {user.role === 1 &&
                    <GridColumn
                        field={SELECTED_FIELD}
                        width="50"
                        // headerSelectionValue={
                        //     // dataState.findIndex((item) => !selectedState[idGetter(item)]) === -1
                        // } // 헤더 선택 확인란의 선택 여부를 정의합니다.
                    />
                }
                <GridColumn field="idx" title="번호" width="70" />
                <GridColumn field="email" title="이메일" width="400"  cell={ user.role === 1 ? userDetailHref : userDetailHrefTeacher } />
                <GridColumn field="name" title="이름" width="150"/>
                <GridColumn field="age" title="나이" width="150"/>
                <GridColumn field="step" title="스텝" width="150"/>
                <GridColumn field="teacher" title="담당 강사" width="150"/>
                <GridColumn field="reg_date" title="등록일" width="150"/>
                <GridColumn field="code" title="프로모션 코드" width="150"/>
            </Grid>
            {user.role === 1 &&
                <MuiGrid item xs={6}>
                    <Button type="button" style={{float: 'left', marginTop: 10}} width={40} variant="contained"
                            onClick={() => user.role === 1 && selectUserDeleteFunction()}>회원 삭제</Button>
                    <Button type="button" style={{float: 'right', marginTop: 10}} width={40} variant="contained"
                            onClick={() => user.role === 1 && navigate("/cms/user/userRegister/admin")}>회원 등록</Button>
                </MuiGrid>
            }
        </>
    )
}

const userDetailHref = (props) => {
    return (
        <td>
            <Link to={`/cms/user/userDetail/${props.dataItem.email}/admin`} state={{ email: props.dataItem.email }}>{props.dataItem.email}</Link>
        </td>
    )

}


const userDetailHrefTeacher = (props) => {
    return (
        <td>
            <Link to={`/cms/user/userDetail/${props.dataItem.email}/teacher`} state={{ email: props.dataItem.email }}>{props.dataItem.email}</Link>
        </td>
    )

}

export default UserList;