import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

// project import
import useAuth from 'hooks/useAuth';
import {LOGOUT, RELOGIN} from "../../store/reducers/actions";
import {getLocalStorageExpireTime} from "../setLocalStorageExpireTime";
import {useState} from "react";
import RouteGuardAlertDialogSlide from "./RouteGuardAlertDialogSlide";
import {useDispatch} from "react-redux";
import {activeAuthToken} from "../../store/reducers/authToken";
import {store} from "../../store";


// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const { isLoggedIn , dispatches , user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open , setOpen] = useState(false);
  const [authError , setAuthError] = useState(false);
  const [message , setMessage] = useState("재 로그인 하세요.");
  const [title ,setTitle] = useState("로그인 오류");
  const [openCount , setOpenCount ] = useState(0);
  const [errorLocation , setErrorLocation] = useState("teacher");

  const location = useLocation();

  const authErrorFunction = (message , errorLocation) => {
    setOpen(true);
    setAuthError(true);
    setErrorLocation(errorLocation)
    setTitle("LOGIN ERROR");
    setMessage(message);
  }

  useEffect(() => {
    /**
     * 로컬 스토리지 확인
     * @type {string}
     */
    const localStorageUser = localStorage.getItem("user");

    //console.log(" = = > AuthGuard Access LOCAL STORAGE = " , localStorageUser);
    /**
     *  로컬스토리지 만료 검증
     * @type {any}
     */
    const result = getLocalStorageExpireTime("user");
    //console.log(" = = > AuthGuard LOCAL STORAGE result =" , result)

    /**
     * 만료 됐을 경우 Null return -> 로그아웃 처리
     */
    if(result == null){
      dispatches({
        type: LOGOUT,
        payload: {
          isLoggedIn: false, // 로그인 여부
          isInitialized: false, // 초기화 여부
          user: null // 로그인한 유저
        }
      })
      dispatch(
          activeAuthToken({
            isToken: false,
            token: ""
          })
      );
      if(location.pathname.split("/").pop() === 'admin') {
        authErrorFunction("로그인 유지 시간 만료. 재 로그인이 필요합니다." , "admin");
      } else if (location.pathname.split("/").pop() === 'teacher'){
        authErrorFunction("로그인 유지 시간 만료. 재 로그인이 필요합니다." , "teacher");
      } else {
        authErrorFunction("로그인 유지 시간 만료. 재 로그인이 필요합니다.");
      }
    }

    /**
     * 로그인 안됐을 때 접근
     */
    if (!isLoggedIn) {
      //console.log(" = = = > AuthGuard NOT LOGIN ")
      // 위치에 따라 다른데
      // console.log(location.pathname)
      // console.log(location.pathname.split("/"))
      // console.log(location.pathname.split("/").pop())
      // console.log(locationStat)

      if(location.pathname.split("/").pop() === 'admin') {
          navigate('/login/admin', {replace: true});
      } else if (location.pathname.split("/").pop() === 'teacher'){
          navigate('/login/teacher', {replace: true});
      } else {
         navigate('/login/admin', {replace: true});
      }

    } else if(isLoggedIn){

      //console.log(" = = = > AuthGuard SUCCESS LOGIN ")
      //console.log(" = = = > AuthGuard SUCCESS LOGIN REQUEST LOCATION = " ,location.pathname.split("/").pop())
      /**
       * 로컬스토리지에는 존재하나 , 컨텍스트에는 존재하지 않을 떄 -> 새로고침 , url 직접 접근 시
       */
      if(localStorageUser != null && user == null) {
        //console.log(" = = = > AuthGuard SUCCESS LOGIN AND NOT EXIST CONTEXT USER")
        //console.log(" = = = > AuthGuard SUCCESS LOGIN AND NOT EXIST CONTEXT USER = " , result);
        /**
         * 다시 로그인
         */
        dispatches({
          type: RELOGIN,
          payload: {
            user: {
              id: result.id,
              name: result.name,
              role: result.role
            }
          }
        })
        // 권한에 맞지 않은 페이지 접근 했을 경우 리턴,
        //console.log(result.role)
        switch (result.role) {
          case 0 :  {
            location.pathname.split("/").pop() === 'admin' && authErrorFunction(" 접근 할 수 없는 페이지입니다. 접근 하시려면 알맞은 권한으로 로그인 해주세요." , 'admin');
            break
          }
          case 1 : {

            location.pathname.split("/").pop() === 'teacher' &&    authErrorFunction(" 접근 할 수 없는 페이지입니다. 접근 하시려면 알맞은 권한으로 로그인 해주세요." , "teacher" );
            break
          }
          default: {
            authErrorFunction(" 접근 할 수 없는 페이지입니다. 접근 하시려면 알맞은 권한으로 로그인 해주세요." );
            break
          }
        }

      } else if(user != null) {
        // user 존재할 경우 권한에 따른 접근
        switch (user.role) {
          case 0 :  {
            location.pathname.split("/").pop() === 'admin' && navigate(-1 , {replace:true})
            break
          }
          case 1 : {
            location.pathname.split("/").pop() === 'teacher' && navigate(-1 ,{replace:true})
            break
          }
          default: {
            navigate(-1)
          }
        }
      }
    }
  }, []);

  return(
      <>
        <RouteGuardAlertDialogSlide open={open} setOpen={setOpen} authError={authError} errorLocation={errorLocation} message={message} title={title} setOpenCount={setOpenCount} openCount={openCount}/>
        {children}
      </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
