import Axios from "../../axios/Axios";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";
import {regEmail} from "../../utils/reg";

export const duplicateEmailCheck = (email , dispatch , setDuplicateEmail , myEmailCheck) => {

    if(!regEmail.test(email)){
        setDuplicateEmail(true);
        throw new Error("올바르지 않은 형식의 이메일입니다.")
    }

    if(myEmailCheck){
        const data = {
            id: myEmailCheck,
            email: email
        }

        Axios.get("/cms/teacher/duplicateEmailMyNotContainsCheck", data, false).then(result => {
            const count = Object.values(result)[0];
            if (count === 0) {
                setDuplicateEmail(false);
                dispatch(
                    showAlert({
                        items: 1,
                        title: "정상",
                        message: "사용할 수 있는 이메일 입니다.",
                        text1: '확인',
                    }),
                );
            } else {
                setDuplicateEmail(true);
                dispatch(
                    showAlert({
                        items: 1,
                        title: "실패",
                        message: "이미 사용중인 이메일 입니다.",
                        text1: '확인',
                    }),
                );
            }

        })
    } else {
        const data = {
            email: email
        }
        Axios.get("/cms/teacher/duplicateEmailCheck", data, false).then(result => {
            const count = result.data;
            if (count === 0) {
                setDuplicateEmail(false);
                dispatch(
                    showAlert({
                        items: 1,
                        title: "정상",
                        message: "사용할 수 있는 이메일 입니다.",
                        text1: '확인',
                    }),
                );
            } else {
                setDuplicateEmail(true);
                dispatch(
                    showAlert({
                        items: 1,
                        title: "실패",
                        message: "이미 사용중인 이메일 입니다.",
                        text1: '확인',
                    }),
                );
            }

        })
    }
}