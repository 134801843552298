import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {useEffect, useState} from "react";
import useAuth from "../../hooks/useAuth";
import {LOGOUT} from "../../store/reducers/actions";
import {useNavigate} from "react-router-dom";
import {activeAuthToken} from "../../store/reducers/authToken";
import {useDispatch} from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const onClick = (setOpen , setOpenCount , openCount , authError , setIsNavigate) => {
    setOpen(false);
    setOpenCount !== undefined && setOpenCount(openCount + 1)
    if(authError) {
        setIsNavigate(true)
    }
}

let RouteGuardAlertDialogSlide = ({open , setOpen , authError , errorLocation , message , title , setOpenCount , openCount}) => {

    const {dispatches} = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isNavigate , setIsNavigate] = useState(false);
    useEffect(() => {
        if(isNavigate) {
            dispatches({
                type: LOGOUT,
                payload: {
                    isLoggedIn: false, // 로그인 여부
                    isInitialized: false, // 초기화 여부
                    user: null // 로그인한 유저
                }
            })
            dispatch(
                activeAuthToken({
                    isToken: false,
                    token: ""
                })
            );
            localStorage.removeItem("persist:root");
            localStorage.removeItem("user");
            if(errorLocation === "admin") navigate("/login/admin" , {replace: true})
            else if (errorLocation === "teacher") navigate("/login/teacher" , {replace: true})
            else navigate(-1 , {replace: true})
        }
    } , [isNavigate])

     return (
         <div>
             <Dialog
                 open={open}
                 TransitionComponent={Transition}
                 keepMounted
                 onClose={() => onClick(setOpen,setOpenCount,openCount, authError , setIsNavigate) }
                 aria-describedby="alert-dialog-slide-description"
             >
                 <DialogTitle>{title}</DialogTitle>
                 <DialogContent>
                     <DialogContentText id="alert-dialog-slide-description">
                         {message}
                     </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                     <Button onClick={() => onClick(setOpen,setOpenCount,openCount, authError ,setIsNavigate)}>닫기</Button>
                 </DialogActions>
             </Dialog>
         </div>
     );
}

export default RouteGuardAlertDialogSlide;