import Axios from "../../axios/Axios";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";

export const duplicateUserEmailCheck = (email , dispatch , setDuplicateEmail) => {
    const params = {
        email: email
    }
    Axios.get("/cms/user/findByUserId" , params , false).then(result => {
        if(result.emailCnt !== 0){
            dispatch(
                showAlert({
                    items: 1,
                    title: "실패",
                    message: '중복되는 아이디 입니다.',
                    text1: '확인',
                }),
            );
            setDuplicateEmail(true);
        } else {
            dispatch(
                showAlert({
                    items: 1,
                    title: "성공",
                    message: '회원 가입이 가능한 아이디입니다.',
                    text1: '확인',
                }),
            );
            setDuplicateEmail(false);
        }
    })
}

export const checkList = [
    true,
    false,
    false,
    false
]