// ==============================|| THEME CONFIG  ||============================== //

export const drawerWidth = 260;

const config = {
  defaultPath: '/',
  fontFamily: `'Public Sans', sans-serif`,
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
};

export default config;
