import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import CommonRoutes from "./CommonRoutes";
import MissingRoutes from "./MissingRoutes";



// ==============================|| ROUTING RENDER ||============================== //
// useEffect(()=>{
//   const user = useSelector((state) => state.type);
//   const auth = isAdmin(user);
// } , [useRoutes])
export default function Routes() {
  return  useRoutes([
    LoginRoutes,
    CommonRoutes,
    MissingRoutes
  ]);
}
