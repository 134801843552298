import {
    Button,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    OutlinedInput, Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {regNumber} from "../../../utils/reg";
import Axios from "../../../axios/Axios";
import {showAlert} from "../../../store/reducers/alertDialogSlideRedux";
import MainCard from "../../../components/MainCard";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {useEffect} from "react";


const CurriculumTimeDetail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [curriculumUsed , setCurriculumUsed] = useState("Y");

    const [curriculumTime , setCurriculumTime] = useState({
        curriculum_time: "",
        reg_date: "",
        used: "Y"
    })


    const location = useLocation();
    const idx = location.state.idx;

    const handleRadio = (e) => {
        setCurriculumUsed(e.target.value);
    }

    useEffect(()=> {
        const params = {
            idx: idx
        }
        Axios.get("/cms/curriculum_time/detail" , params , false).then(result => {

            if(result.data != null){
                setCurriculumTime(result.data);
                setCurriculumUsed(result.data.used)
            } else {
                dispatch(
                    showAlert({
                        items: 1,
                        title: "실패",
                        message: '잘못된 접근입니다.',
                        text1: '확인',
                    }),
                );
            }
        })
    },[])

    const deleteByCurriculumTime = () => {
        const changeCurriculumTimeList = [];
        changeCurriculumTimeList.push(idx);
        Axios.delete("/cms/curriculum_time/delete" , changeCurriculumTimeList , false).then(result => {
            dispatch(
                showAlert({
                    items: 1,
                    title: '수업 시간 삭제 요청',
                    message: '정상적으로 삭제 되었습니다.',
                    text1: '닫기',
                    sucCallback: () => {
                        navigate("/cms/setting/curriculumTime/admin")
                    }
                }),
            );
        })
    }

    return (
        <Grid container spacing={2} >
            <Formik
                initialValues={{
                    curriculum_time: curriculumTime.curriculum_time,
                    submit: null
                }}
                // Yub -> Validation 라이브러리
                // html name 을 인식해서 사용함.
                validationSchema={Yup.object().shape({
                    curriculum_time: Yup.string().max(255).required('수업 시간을 입력해주세요.')
                })}
                onSubmit={(values, actions) => {
                    const data = new FormData();
                    data.append("curriculum_time" , values.curriculum_time);
                    data.append("used" , curriculumUsed);
                    data.append("idx" , idx);

                    Axios.patch("/cms/curriculum_time/modify" , data , true).then(result => {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "성공",
                                message: '수강일 변경에 성공하였습니다.',
                                text1: '확인',
                                sucCallback: ()=> {
                                    navigate("/cms/setting/curriculumTime/admin")
                                }
                            }),
                        );
                    });
                }}
                enableReinitialize
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12}>
                            <MainCard title="수업시간 상세 정보">
                                <Grid container spacing={4}>
                                    <Grid item sx={{ width: '100%' }} >
                                        <InputLabel htmlFor="crriculum_time">수업시간</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="curriculum_time"
                                            name="curriculum_time"
                                            value={values.curriculum_time}
                                            onChange={ (e) => {handleChange(e)}}
                                            style={{fontWeight:"bold"}}
                                            error={Boolean(( errors.curriculum_time))}
                                        />
                                        { ( errors.curriculum_time) && (
                                            <FormHelperText error id="standard-weight-helper-text-curriculum-time">
                                                {errors.curriculum_time}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel id="demo-radio-buttons-group-label">사용 여부</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={curriculumUsed}
                                            name="used-radio-group"
                                            onChange={ (e) => {
                                                handleRadio(e)
                                            }}
                                        >
                                            <FormControlLabel value="Y" control={<Radio size="medium"/>} label="사용" />
                                            <FormControlLabel value="N" control={<Radio size="medium"/>} label="사용 안함" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item sx={{ width: '100%' }} >
                                        <InputLabel htmlFor="reg_date">등록일</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="reg_date"
                                            name="reg_date"
                                            value={curriculumTime.reg_date}
                                            readOnly={true}
                                            style={{fontWeight:"bold"}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Button type="button" style={{float: 'left' , marginTop: 10}} width={40} variant="contained" onClick={()=> {
                                            try {
                                                deleteByCurriculumTime()
                                            } catch (e) {
                                                dispatch(
                                                    showAlert({
                                                        items: 1,
                                                        title: "실패",
                                                        message: e.message,
                                                        text1: '확인',
                                                    }),
                                                );
                                            }
                                        }
                                        }
                                        >삭제</Button>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} width={40} type="button" onClick={()=>   navigate("/cms/setting/curriculumTime/admin")}>
                                            취소
                                        </Button>
                                        <Button variant="contained" style={{float: 'right' , marginTop: 10 , marginLeft: 10}} type="submit">
                                            수정
                                        </Button>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    );
}

export default CurriculumTimeDetail;