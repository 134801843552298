import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useEffect} from "react";
import Axios from "../../axios/Axios";
import {useState} from "react";
import {getter} from "@progress/kendo-react-common";
import {useCallback} from "react";
import {getSelectedState, Grid, GridColumn} from "@progress/kendo-react-grid";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {FormHelperText, Grid as MuiGrid, MenuItem, OutlinedInput, Select} from "@mui/material";
import {CloseOutlined} from "@ant-design/icons";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";

export const SelectUserDialog = ({ onClose,  open , setSelectedValue }) => {
    //const { onClose, selectedValue, open } = props;
    const [category , setCategory] = useState("email");
    const [keyword , setKeyword] = useState("");
    const DATA_ITEM_KEY = "email";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const [selectedState, setSelectedState] = useState({});
    const [userList , setUserList ] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [caution , setCaution] = useState(false);

    const handleClose = () => {
        const selectObject = selectedState;
        const ids = Object.keys(selectObject);
        const values = Object.values(selectObject);
        if(values.length !== ids.length) throw new Error("유효하지 않은 요청입니다.")
        let changeUserList = [];
        values.map( (valueBoolean , i ) => {
            if(valueBoolean === true){
                const changeUser = ids.at(i);
                changeUserList.push(changeUser);
            }
        })

        if(changeUserList.length >= 2){
            setCaution(true);
            throw new Error("회원은 한명만 선택 가능합니다.")
        }
        setSelectedValue(changeUserList);
        onClose();
    };


    /**
     *  REST 요청 카테고리 및 상태 변경
     * @param event
     */
    const handleChange = (event) => {
        const value = event.target.value;
        setCategory(value)
    };

    /**
     * 검색 키워드
     * @param event
     */
    const keywordHandleChange = (event) => {
        setKeyword(event.target.value);
    }


    const enterFunction = (event) => {
        if(event.keyCode === 13){
            categorySelectFunction()
        }
    }
    /**
     * 페이지 처리
     * @type {{take: number, skip: number}}
     */
    let param = {
        skip: 0,
        take: 10
    }

    const [page, setPage] = useState(param);

    /**
     * 페이지 변경
     * @param event
     */
    const pageChange = (event) => {
        setPage(event.page);
    };

    /**
     * 카테고리 검색히 다시 페이지 1로 초기화
     */
    const categorySelectFunction = () => {
        axiosForm();
        page.skip = 0;
        setPage(page);
    }


    let categorySelect = {
        category: category,
        keyword: keyword
    }

    /**
     * axios
     */
    let axiosForm = () => {
        Axios.get("/cms/user/paymentUserList" ,  categorySelect).then(result => {
                const resultUserList = result.data;
                let copy = [...userList];
                copy = resultUserList.map((user) => ({
                    email: user.email,
                    name: user.name,
                    phone_number: user.phone_number
                }));
                copy.reverse()
                setUserList(copy);
            }
        )
    }


    /**
     * 한개 선택
     * @type {(function(*): void)|*}
     */
    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    /**
     * 전체 선택
     * @type {(function(*): void)|*}
     */
    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked; // 눌려있는가 체크
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked; // 체크로 변환
        });
        setSelectedState(newSelectedState);
    }, []);

    useEffect( () => {
        axiosForm();
    } , [])

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>학습자 선택 <CloseOutlined style={{float:"right" , padding:1}}  onClick={ ()=> {handleClose()}}/> </DialogTitle>
            <MuiGrid container spacing={3} rowSpacing={1} padding={2} marginBottom={5}>
                <MuiGrid item sx={{ width: '30%'}}>
                    <Select
                        fullWidth
                        labelId="select-label"
                        id="simple-select"
                        value={category}
                        onChange={handleChange}
                    >
                        <MenuItem value={'name'}>이름</MenuItem>
                        <MenuItem value={'email'}>이메일</MenuItem>
                    </Select>

                </MuiGrid>
                <MuiGrid item sx={{ width: '55%'}}>
                    <OutlinedInput
                        fullWidth
                        placeholder="검색어를 입력해주세요."
                        onChange={keywordHandleChange}
                        onKeyDown={enterFunction}
                    />
                </MuiGrid>
                <MuiGrid item sx={{ width: '15%' }}>
                    <Button  type="button" onClick={() => {
                        categorySelectFunction()
                    }} >검색</Button>
                </MuiGrid>
            </MuiGrid>
            <Grid
                style={{ height: "400px" , margin: "auto" , marginLeft: 10 , marginRight: 10}}

                data={
                    userList.slice(page.skip , page.take + page.skip).map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))
                }
                pageable={{ buttonCount: 5 }} //pageSizes: true
                onPageChange={pageChange}
                skip={page.skip}
                take={page.take}
                total={userList.length}
                expandField="expanded"

                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                }}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
            >
                <GridColumn
                    field={SELECTED_FIELD}
                    width="50"
                    // headerSelectionValue={
                    //     // dataState.findIndex((item) => !selectedState[idGetter(item)]) === -1
                    // } // 헤더 선택 확인란의 선택 여부를 정의합니다.
                />
                <GridColumn field="email" title="이메일" width="270" />
                <GridColumn field="name" title="이름" width="120"/>
                <GridColumn field="phone_number" title="핸드폰번호" width="120"/>
            </Grid>
            <MuiGrid item xs={12} textAlign="center" margin={4}>
                {caution &&
                    <FormHelperText error id="standard-weight-helper-text-caution" style={{fontWeight:"bold"}}>
                        한 명의 학습자만 선택해주세요.
                    </FormHelperText>
                }
                <Button type="button" style={{ marginTop: 10}} width={40} variant="contained" onClick={ handleClose }>학습자 선택</Button>
            </MuiGrid>
        </Dialog>
    );
}


SelectUserDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};