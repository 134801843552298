import {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {getter} from "@progress/kendo-react-common";
import {getSelectedState, Grid, GridColumn} from "@progress/kendo-react-grid";
import {showAlert} from "../../../store/reducers/alertDialogSlideRedux";
import Axios from "../../../axios/Axios";
import {Button, Grid as MuiGrid, MenuItem, OutlinedInput, Select, Typography} from "@mui/material";

const CurriculumTimeList = () => {
    const [category , setCategory] = useState("curriculum_time");
    const [keyword , setKeyword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [curriculumTimeList , setCurriculumTimeList ] = useState([]);

    /**
     *  REST 요청 카테고리 및 상태 변경
     * @param event
     */
    const handleChange = (event) => {
        const value = event.target.value;
        setCategory(value)
    };

    /**
     * 검색 키워드
     * @param event
     */
    const keywordHandleChange = (event) => {
        setKeyword(event.target.value);
    }

    /**
     * 페이지 처리
     * @type {{take: number, skip: number}}
     */
    let param = {
        skip: 0,
        take: 10
    }

    const [page, setPage] = useState(param);

    /**
     * 페이지 변경
     * @param event
     */
    const pageChange = (event) => {
        setPage(event.page);
    };

    /**
     * REST 요청 키워드
     * @type {{category: string, keyword: string}}
     */
    let categorySelect = {
        category: category,
        keyword: keyword
    }
    /**
     * 카테고리 검색히 다시 페이지 1로 초기화
     */
    const categorySelectFunction = () => {
        axiosForm();
        page.skip = 0;
        setPage(page);
    }

    let count = (function (){
        let sum =0;
        return function a () {
            return ++sum;
        };
    })();

    const enterFunction = (event) => {
        if(event.keyCode === 13){
            categorySelectFunction()
        }
    }

    const DATA_ITEM_KEY = "idx";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const [selectedState, setSelectedState] = useState({});

    /**
     * 한개 선택
     * @type {(function(*): void)|*}
     */
    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    /**
     * 전체 선택
     * @type {(function(*): void)|*}
     */
    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked; // 눌려있는가 체크
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked; // 체크로 변환
        });
        setSelectedState(newSelectedState);
    }, []);

    /**
     * 커리큘럼 선텍 해서 삭제
     */
    const selectCurriculumTimeDeleteFunction = () => {
        const selectObject = selectedState;
        const ids = Object.keys(selectObject);
        const values = Object.values(selectObject);

        if(values.length !== ids.length) throw new Error("유효하지 않은 요청입니다.")

        let changeCurriculumTimeList = [];
        values.map( (valueBoolean , i ) => {
            if(valueBoolean === true){
                const changeCurriculumTime = ids.at(i);
                changeCurriculumTimeList.push(changeCurriculumTime);
            }
        })
        dispatch(
            showAlert({
                items: 2,
                title: "삭제하시겠습니까?",
                message: "삭제하시겠습니까?",
                text1: '확인',
                text2: '취소',
                sucCallback: () => {
                    deleteByCurriculumTime(changeCurriculumTimeList);
                }
            }),
        );
    }

    const deleteByCurriculumTime = (changeCurriculumTimeList) => {
        Axios.delete("/cms/curriculum_time/delete" , changeCurriculumTimeList , false).then(result => {
            dispatch(
                showAlert({
                    items: 1,
                    title: '수업 시간 삭제 요청',
                    message: '정상적으로 삭제 되었습니다.',
                    text1: '닫기'
                }),
            );
            axiosForm();
        })
    }

    /**
     * axios
     */
    let axiosForm = () => {
        Axios.get("/cms/curriculum_time/list" ,  categorySelect).then(result => {
                const resultCurriculumTimeList = result.data.list;
                let copy = [...curriculumTimeList];
                copy = resultCurriculumTimeList.map((curriculumTime) => ({
                    index: count(),
                    idx: curriculumTime.idx,
                    curriculum_time: curriculumTime.curriculum_time,
                    used: curriculumTime.used,
                    reg_date: curriculumTime.reg_date
                }));
                copy.reverse()
                setCurriculumTimeList(copy);
            }
        )
    }

    useEffect( () => {
        axiosForm();
    } , [])

    return (
        <>
            <MuiGrid container spacing={3} rowSpacing={1} marginBottom={10}>
                <MuiGrid item sx={{ width: '30%'}}>
                    <Select
                        fullWidth
                        labelId="select-label"
                        id="simple-select"
                        value={category}
                        onChange={handleChange}
                    >
                        <MenuItem value={'curriculum_time'}>수업시간</MenuItem>
                    </Select>

                </MuiGrid>
                <MuiGrid item sx={{ width: '55%'}}>
                    <OutlinedInput
                        fullWidth
                        placeholder="검색어를 입력해주세요."
                        onChange={keywordHandleChange}
                        onKeyDown={enterFunction}
                    />
                </MuiGrid>
                <MuiGrid item sx={{ width: '15%' }}>
                    <Button  type="button" onClick={() => {
                        categorySelectFunction()
                    }} >검색</Button>
                </MuiGrid>
            </MuiGrid>
            <MuiGrid item sx={{ width: '100%'}}>
                <Typography  style={{float:"right" , marginRight:2}} variant="h6">총 {curriculumTimeList.length} 건</Typography>
            </MuiGrid>
            <Grid
                style={{ height: "400px" , textAlign:"center" }}

                data={
                    curriculumTimeList.slice(page.skip , page.take+ page.skip).map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))
                }
                pageable={{ buttonCount: 3}}
                onPageChange={pageChange}
                skip={page.skip}
                take={page.take}
                total={curriculumTimeList.length}
                expandField="expanded"

                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                }}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
            >
                <GridColumn
                    field={SELECTED_FIELD}
                    width="50"
                />
                <GridColumn field="idx" title="번호" width="70"  cell={HideIndexNumber}/>
                <GridColumn field="curriculum_time" title="수업시간"  width="160" style={{width:"40%"}} cell={curriculumDetailHref}/>
                <GridColumn field="used" title="사용여부" width="160" cell={UsedChangeText}/>
                <GridColumn field="reg_date" title="등록일" style={{width:"15%"}}/>
            </Grid>
            <MuiGrid item xs={6}>
                <Button type="button" style={{float: 'left' , marginTop: 10}} width={40} variant="contained" onClick={()=> {
                    try {
                        selectCurriculumTimeDeleteFunction()
                    } catch (e) {
                        dispatch(
                            showAlert({
                                items: 1,
                                title: "실패",
                                message: e.message,
                                text1: '확인',
                            }),
                        );
                    }
                }
                }
                >삭제</Button>
                <Button type="button" style={{float: 'right' , marginTop: 10}} width={40} variant="contained" onClick={()=> navigate("/cms/setting/curriculumTimeRegistry/admin")}>등록</Button>
            </MuiGrid>
        </>
    )
}

const curriculumDetailHref = (props) => {
    return (
        <td style={{textAlign:"center"}}>
            <Link to={`/cms/setting/curriculumTimeDetail/${props.dataItem.idx}/admin`} state={{ idx: props.dataItem.idx }}>{props.dataItem.curriculum_time}</Link>
        </td>
    )

}

const UsedChangeText = (props) => {
    return (
        <td style={{textAlign:"center"}}>
            {props.dataItem.used === 'Y' ? '사용' : '사용안함'}
        </td>
    )
}

const HideIndexNumber = (props) => {
    return (
        <td style={{textAlign:"center"}}>
            {props.dataItem.index}
        </td>
    )
}


export default CurriculumTimeList;