import CryptoJS from "crypto-js";


export const setLocalStorageExpireTime = (keyName, keyValue , expire) => {
    const cipherByValue = CryptoJS.AES.encrypt(JSON.stringify(keyValue), process.env.REACT_APP_AES_KEY).toString();

    const localStorageObject = {
        value: cipherByValue,
        expire: Date.now() + expire
    }

    const JSONStringifyByValue = JSON.stringify(localStorageObject);
    localStorage.setItem( keyName , JSONStringifyByValue);
}


export const getLocalStorageExpireTime = (keyName) => {
    const localStorageUser = localStorage.getItem(keyName);

    if(!localStorageUser){
        return null;
    }

    const userParse = JSON.parse(localStorageUser);
    const decryptUser = CryptoJS.AES.decrypt(userParse.value , process.env.REACT_APP_AES_KEY);
    const loginUser = decryptUser.toString(CryptoJS.enc.Utf8);
    const loginUserParse = JSON.parse(loginUser);

    if(Date.now() > userParse.expire){
        localStorage.removeItem(keyName);
        localStorage.removeItem("persist:root");
        return null;
    }

    return loginUserParse;
}